import React from 'react'
import PropTypes from 'prop-types'

import {
  Divider,
} from '@mui/material'
import {
  useEngagement,
  useSessionUser
} from 'hooks'
import { ResponsiveContent } from 'components'

import ActivityLog from './components/ActivityLog'
import CallActionsSection from './components/CallActionsSection'
import CallDocumentsSection from './components/CallDocumentsSection'
import CallPrepSection from './components/CallPrepSection'
import TimeSelectionStep from './components/TimeSelectionStep'
import ProposalMessage from './components/ProposalMessage'
import CallAvailability from './components/CallAvailability'
import ProposalCanceled from './components/ProposalCanceled'
import ProposalExpired from './components/ProposalExpired'
import ProposalRejected from './components/ProposalRejected'
import FinalizeEngagementSection from './components/FinalizeEngagementSection'
import ProposedAgenda from './components/ProposedAgenda'

const EngagementContent = ({ engagementId }) => {
  const { isCustomerUser } = useSessionUser()
  const {
    isProposalCanceled,
    isProposalExpired,
    isProposalRejected,
    isCallProposed,
    isInScheduling,
    isCallUpcoming,
    isCallComplete,
    isClosed,
    isCanceled,
    isFeedbackRequired,
    isParticipant,
    isProposalExpiredOrRejected,
    assets,
    agenda
  } = useEngagement(engagementId)

  const sections = (isCustomerUser ? [
    // cu sections
    {
      key: 'time-selection',
      Component: TimeSelectionStep,
      visible: isInScheduling
    },
    {
      key: 'proposal-canceled',
      Component: ProposalCanceled,
      visible: isProposalCanceled,
    },
    {
      key: 'proposal-expired',
      Component: ProposalExpired,
      visible: isProposalExpired,
    },
    {
      key: 'proposal-rejected',
      Component: ProposalRejected,
      visible: isProposalRejected,
      hideOthers: true
    },
    {
      key: 'proposal-message',
      Component: ProposalMessage,
      visible: isCallProposed,
    },
    {
      key: 'call-availability',
      Component: CallAvailability,
      visible: isCallProposed && !isParticipant
    },
    {
      key: 'call-actions',
      Component: CallActionsSection,
      visible: isCallUpcoming || (isFeedbackRequired && !isParticipant)
    },
    {
      key: 'call-documents',
      Component: CallDocumentsSection,
      visible: !isCanceled && assets?.length
    },
    {
      key: 'call-prep',
      Component: CallPrepSection,
      visible: isCallUpcoming
    },
    {
      key: 'finalize-call',
      Component: FinalizeEngagementSection,
      visible: !isParticipant && isCallComplete && !isClosed
    },
    {
      key: 'activity-log',
      Component: ActivityLog,
      visible: !isCallProposed && !isProposalExpiredOrRejected && !isCanceled
    }
  ] : [ // advisor sections
    {
      key: 'proposal-canceled',
      Component: ProposalCanceled,
      visible: isProposalCanceled,
    },
    {
      key: 'proposal-expired',
      Component: ProposalExpired,
      visible: isProposalExpired,
    },
    {
      key: 'proposal-rejected',
      Component: ProposalRejected,
      visible: isProposalRejected,
      hideOthers: true
    },
    {
      key: 'proposed-agenda',
      Component: ProposedAgenda,
      visible: isProposalExpired && agenda?.length
    },
    {
      key: 'time-selection',
      Component: TimeSelectionStep,
      visible: isInScheduling
    },
    {
      key: 'call-actions',
      Component: CallActionsSection,
      visible: isCallUpcoming || isFeedbackRequired
    },
    {
      key: 'call-prep',
      Component: CallPrepSection,
      visible: isCallUpcoming
    },
    {
      key: 'finalize-call',
      Component: FinalizeEngagementSection,
      visible: isCallComplete && !isClosed
    },
    {
      key: 'activity-log',
      Component: ActivityLog,
      visible: !isCallProposed && !isProposalExpiredOrRejected && !isCanceled
    }
  ])

  const getSectionsToDisplay = () => {
    let visibleSections = sections.filter(({ visible }) => visible)
    if (visibleSections.find((section) => section.hideOthers)) {
      visibleSections = visibleSections.filter(({ hideOthers }) => hideOthers)
    }
    return visibleSections
  }

  const sectionsToDisplay = getSectionsToDisplay()
  const activityLogExpandedByDefault = sectionsToDisplay.length === 1

  return (
    <ResponsiveContent>
      {sectionsToDisplay.map(({ key, Component }, index) => (
        <React.Fragment key={key}>
          <Component
            key={key}
            engagementId={engagementId}
            // if activity log is the only section rendered, expand it by default
            {...(key === 'activity-log' ? { expandedByDefault: activityLogExpandedByDefault } : {})}
          />
          {index < sectionsToDisplay.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </ResponsiveContent>
  )
}

EngagementContent.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default EngagementContent
