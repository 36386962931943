import React from 'react'
import { customAlert } from 'utils-em'

export default function useAccountSaved (orgId) {
  const [savedListLoaded, setSavedListLoaded] = React.useState('')
  const [accountSaved, setAccountSaved] = React.useState(false)
  const checkIfAccountSaved = async () => {
    try {
      const res = await fetch(`${__API_HOST__}/v1/accountListOrgs?orgId=${orgId}`, {
        credentials: 'include',
        method: 'GET',
      })
      if (res.error) throw new Error(res.error.message)
      const json = await res.json()
      if (json.data?.length > 0) {
        return setAccountSaved(true)
      }
      return setAccountSaved(false)
    } catch (error) {
      customAlert('Unable to update accounts. Please contact support.', true)
      return setAccountSaved(false)
    }
  }

  React.useEffect(() => {
    if (!orgId) return
    if (!savedListLoaded || savedListLoaded !== orgId) {
      checkIfAccountSaved()
      setSavedListLoaded(orgId)
    }
  }, [orgId])

  return { accountSaved, savedListLoaded, checkIfAccountSaved }
}
