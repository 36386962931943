import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Divider,
  Typography,
  Link
} from '@mui/material'
import {
  useEngagement,
  useJsonAPIUpsert
} from 'hooks'
import {
  constants,
  navigateTo,
} from 'utils-em'

const ProposalExpired = ({ opportunityId, engagementId }) => {
  const { isAdvisor, advisor, agenda, dateAdvisorIndicatedAvailable } = useEngagement(engagementId)
  const { upsert } = useJsonAPIUpsert()

  const [isSettingAvailable, setIsSettingAvailable] = React.useState(false)

  const markAvailable = async () => {
    setIsSettingAvailable(true)
    await upsert({
      type: 'engagements',
      id: engagementId,
      advisorIndicatedAvailable: true,
      riders: ['advisorIndicatedAvailable']
    })
    opportunityId && navigateTo(`/a/opportunity/${opportunityId}`)
    setIsSettingAvailable(false)
  }

  // Advisor UAF opportunity
  if (isAdvisor && opportunityId) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
        {dateAdvisorIndicatedAvailable ? (
          <>
            <Typography variant="h2">Thanks for letting us know that you are available.</Typography>
            <Typography variant="body1">
              We will notify the client. If they are able to meet, they will reach out to you with proposed call times.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h2">The 7 day timeframe for accepting this opportunity has passed.</Typography>
            <Typography variant="body1">
              You will be able to view this opportunity in your
              {' '}
              <Link onClick={() => navigateTo('/a/activity')}>My Activity</Link>
              {' '}
              page.
            </Typography>
            <Typography variant="body1">
              Are you available for an engagement with this client? If so, click the button below and we will notify them.
              If they are able to meet, they will reach out to you with proposed call times.
            </Typography>
            <Box>
              <Button variant="outlined" onClick={markAvailable} disabled={isSettingAvailable}>
                I am available to engage
              </Button>
            </Box>
          </>
        )}

        <Divider />

        <Typography variant="h3">Proposed agenda:</Typography>
        <Typography variant="body1" color="neutral.black" sx={{ whiteSpace: 'pre-wrap', ml: 1 }}>
          {agenda}
        </Typography>
      </Box>
    )
  }

  // Advisor non-UAF engagement, "available to engage" button
  if (isAdvisor && !dateAdvisorIndicatedAvailable && !opportunityId) {
    return (
      <>
        <Typography variant="h2">
          {`The ${constants.ENGAGEMENT_PROPOSAL_EXPIRATION_TIME_IN_DAYS} day timeframe for accepting this opportunity has passed.`}
        </Typography>
        <Typography variant="body1">
          You will be able to view this opportunity in your&nbsp;
          <Link onClick={() => navigateTo('/a/activity')}>Activity History</Link>
          &nbsp;list.
        </Typography>
        <Typography variant="body1">
          Are you available for an engagement with this client? If so, click the button below and we will notify them. If they are able to meet, they will reach out to you with proposed call times.
        </Typography>
        <Box>
          <Button variant="outlined" onClick={markAvailable}>
            I am Available to Engage
          </Button>
        </Box>
      </>
    )
  }

  // Advisor non-UAF engagement, "available to engage" marked
  if (isAdvisor && dateAdvisorIndicatedAvailable && !opportunityId) {
    return (
      <>
        <Typography variant="h2">
          You have let the client know that you are available.
        </Typography>
        <Typography variant="body1">
          The client has been notified that you are available. If they are able to meet, they will reach out to you with proposed call times.
        </Typography>
      </>
    )
  }

  // Customer user view of expired proposal
  return (
    <>
      <Typography variant="h3">
        {'We\'re sorry!'}
      </Typography>
      <Typography variant="body1">
        {`${advisor?.firstName} did not respond to your call proposal within the ${constants.ENGAGEMENT_PROPOSAL_EXPIRATION_TIME_IN_DAYS} day window. `}
        <Link onClick={() => navigateTo('/c/search')}>Find another advisor</Link>
        &nbsp;from our network.
      </Typography>
    </>
  )
}

ProposalExpired.defaultProps = {
  opportunityId: null
}

ProposalExpired.propTypes = {
  opportunityId: PropTypes.string,
  engagementId: PropTypes.string.isRequired
}

export default ProposalExpired
