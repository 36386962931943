import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  IconButton,
  Link,
  Typography
} from '@mui/material'

import {
  useEmissaryTheme,
  useSessionUser,
  useJsonAPIGetAll
} from 'hooks'
import {
  Phone
} from 'icons'

import {
  ACTION_CONSTANTS,
  navigateTo,
  confirmDialog,
  recordUserAction,
  getOrgAvailabilityStatus
} from 'utils-em'

const TalkToSomeone = ({ organizationId, isResponsive, hideDisabledMessage }) => {
  const { isSmOrSmaller } = useEmissaryTheme()
  const { isUnlimitedByAccount, id } = useSessionUser()
  const { objects: activeEngagements, loaded: engagementLoaded, } = useJsonAPIGetAll('engagements', {
    queryStringParams: {
      filter: [
        { name: 'customerUserId', op: 'eq', val: id },
        { name: 'organizationId', op: 'eq', val: organizationId },
        {
          name: 'state',
          op: 'not_in',
          val: [
            'rejected',
            'expired',
            'completed',
            'canceled',
            'active',
            'proposal-canceled'
          ]
        },
      ]
    },
  })

  const [unlimitedStatus, setUnlimitedStatus] = React.useState(null)

  React.useEffect(() => {
    const fetchOrgAvailability = async () => {
      const availability = await getOrgAvailabilityStatus(id, organizationId)
      setUnlimitedStatus(availability?.status)
    }
    fetchOrgAvailability()
  }, [id, organizationId])

  if (!unlimitedStatus) {
    return null
  }
  const isDisabled = (isUnlimitedByAccount || (unlimitedStatus === 'engageable')) && (!engagementLoaded || activeEngagements?.length > 0)

  if (!engagementLoaded) { return null }

  const handleClick = (e) => {
    if (unlimitedStatus === 'depleted') {
      confirmDialog({
        title: 'You\'re out of account credits',
        description: 'We\'re sorry, your organization has used all of the allotted account credits on its contract and a call with this account is unavailable at this time.',
      })
      recordUserAction(ACTION_CONSTANTS.outOfAccountCredits, { id: organizationId }, 'organization')
    } else {
      e.currentTarget.blur()
      navigateTo((unlimitedStatus === 'engageable' || (unlimitedStatus === 'checkAllotment' && isUnlimitedByAccount)) ? `/c/talk-to-someone/${organizationId}` : '#coaching')
    }
  }

  if (isResponsive && isSmOrSmaller) {
    return (
      <IconButton
        disabled={isDisabled}
        onClick={handleClick}
        sx={{ color: 'primary.main', '&:hover': { backgroundColor: 'primary.lightest' } }}
      >
        <Phone />
      </IconButton>
    )
  }

  return (
    <Box>
      <Button
        disabled={isDisabled}
        variant="contained"
        onClick={handleClick}
      >
        Talk to someone
      </Button>
      {isDisabled && !hideDisabledMessage && (
        <Typography variant="tiny" sx={{ ml: 2 }}>
          You already have a pending call proposal for this organization.&nbsp;
          <Link href={`/c/engagements/${activeEngagements.at(-1).id}`}>View proposal</Link>
        </Typography>
      )}
    </Box>
  )
}

TalkToSomeone.defaultProps = {
  isResponsive: false,
  hideDisabledMessage: false
}

TalkToSomeone.propTypes = {
  organizationId: PropTypes.number.isRequired,
  isResponsive: PropTypes.bool,
  hideDisabledMessage: PropTypes.bool
}

export default TalkToSomeone
