import moment from 'moment'
import { uniqBy } from 'lodash'
import { CheckCircle, Clock, CloseCircle, Phone } from 'icons'
import {
  formatDate,
  formatDateTime,
  getAdvisorMostRecentRelevantCompany,
  numberOfDaysBetweenDates,
  simpleHttpFetch,
  downloadFile
} from 'utils-em'
import { emissaryTheme } from 'themes'

export const ENGAGEMENT_STEPS = [
  'PROPOSED',
  'SCHEDULING',
  'PREP_FOR_CALL',
  'COMPLETE_CALL',
  'SUBMIT_FEEDBACK',
  'FEEDBACK_SUBMITTED'
]

export const ENGAGEMENT_COLOR_MAPPING = {
  [ENGAGEMENT_STEPS[0]]: emissaryTheme.palette.warning.light,
  [ENGAGEMENT_STEPS[1]]: emissaryTheme.palette.brand.orange,
  [ENGAGEMENT_STEPS[2]]: emissaryTheme.palette.success.light,
  [ENGAGEMENT_STEPS[3]]: emissaryTheme.palette.success.light,
  [ENGAGEMENT_STEPS[4]]: emissaryTheme.palette.primary.light,
  [ENGAGEMENT_STEPS[5]]: emissaryTheme.palette.neutral.darkGrey
}

export const ACTIVITY_STATUS_MAP = {
  pending: { text: 'Pending', Icon: Clock, iconColor: 'neutral.disabled' },
  upcoming: { text: 'Upcoming', Icon: Phone, iconColor: 'warning.main' },
  complete: { text: 'Complete', Icon: CheckCircle, iconColor: 'success.main' },
  closed: { text: 'Closed', Icon: CloseCircle, iconColor: 'neutral.darkGrey' },
}

export const REJECT_REASON_MAP_FOR_CU = {
  UNQUALIFIED: 'Unable to meet your expectations on this specific topic',
  WRONG_COMPANY: 'Unavailable at this time',
  CONFLICT_OF_INTEREST: 'Conflict of interest',
  BUSY: 'Unavailable at this time',
  OTHER: 'Unavailable at this time',
  PAYMENT_RATE: 'Emissary is reviewing this request'
}

export const REJECT_REASON_MAP_FOR_ADVISOR = {
  UNQUALIFIED: 'I don\'t feel qualified',
  WRONG_COMPANY: 'I never worked for this company',
  CONFLICT_OF_INTEREST: 'Conflict of interest',
  BUSY: 'Not available',
  OTHER: 'Other',
  PAYMENT_RATE: 'Insufficient rate'
}

export const getEngagementDetails = (engagement) => {
  const missedProposalDateUpdated = engagement.timeslotProposals?.find((p) => p.status === 'CALL_MISSED')
  const relevantProposal = engagement.relevantTimeslotProposals?.at(-1)
  const { dateCreated, sellerActivityStatusDetail, rejectReason, engagementRejectReasons } = engagement
  const relevantTimeslot = getEngagementMostRelevantTimeslot(engagement)
  const daysSinceProposal = numberOfDaysBetweenDates(relevantProposal?.dateCreated, new Date(), { truncateTime: true })

  if (sellerActivityStatusDetail === 'closed_proposal_rejected') {
    let rejectReasonToDisplay = rejectReason
    if (!rejectReasonToDisplay) {
      rejectReasonToDisplay = engagementRejectReasons?.at(-1)?.rejectReason
    }
    const rejectReasonText = rejectReasonToDisplay && rejectReasonToDisplay in REJECT_REASON_MAP_FOR_CU && REJECT_REASON_MAP_FOR_CU[rejectReasonToDisplay]
    return `Call proposal rejected by advisor on ${formatDate(dateCreated)}: ${rejectReasonText}`
  }

  switch (sellerActivityStatusDetail) {
    case 'pending_proposal': return `Call proposal sent on ${formatDate(dateCreated)}`
    case 'pending_call_advisor_sent_times': return daysSinceProposal === 0
      ? 'Call times received today: Please respond'
      : `Call times received ${daysSinceProposal} day${daysSinceProposal !== 1 ? 's' : ''} ago: Please respond`
    case 'pending_call_seller_sent_times': return `Call times sent on ${formatDate(relevantProposal?.dateCreated)}`
    case 'pending_call_times_expired': return 'Call times expired: Please send updated call times'
    case 'pending_call_missed': return `Call marked as missed on ${formatDate(missedProposalDateUpdated)}`
    case 'upcoming_call_scheduled': return `Call scheduled for ${formatDateTime(relevantTimeslot?.startTime, { showTimezoneSuffix: true })}`
    case 'complete_call_seller_feedback_requested': return 'Feedback requested'
    case 'complete_call_seller_feedback_complete': return '-'
    case 'closed_proposal_expired': return 'Call proposal expired'
    default: return '-'
  }
}

function getProposals (engagement) {
  const proposals = [...engagement.relevantTimeslotProposals || [], ...engagement.timeslotProposals || []]
  const openProposals = proposals.filter((tp) => tp && tp.status !== 'CALL_MISSED' && tp.status !== 'RESCHEDULED' && tp.status !== 'REJECTED')
  const uniqueProposals = uniqBy(openProposals, (p) => p.id)
  const sortedProposals = uniqueProposals.sort((a, b) => (a.dateCreated > b.dateCreated ? -1 : 1))

  return sortedProposals
}

function getProposalTimeslots (engagement) {
  if (engagement.timeslots && engagement.timeslots.length) {
    return engagement.timeslots
  }
  return engagement.acceptedTimeslots || []
}

export function getMostRecentProposal (engagement) {
  return getProposals(engagement)[0]
}

export function isProposalTimeslotsExpired (proposal) {
  return getProposalTimeslots(proposal).every((t) => Date.parse(t.startTime) < Date.parse(new Date()))
}

export function sortEngagementsByEarliestCallTime (engagements) {
  return engagements.sort((a, b) => {
    const aStartTime = getEngagementMostRelevantTimeslot(a).startTime
    const bStartTime = getEngagementMostRelevantTimeslot(b).startTime
    return Date.parse(aStartTime) - Date.parse(bStartTime)
  })
}

export function getEngagementMostRelevantTimeslot (engagement) {
  const selectedTimeslotProposal = getProposals(engagement).find((tp) => tp.status === 'ACCEPTED')
  if (!selectedTimeslotProposal) {
    return null
  }

  return getProposalTimeslots(selectedTimeslotProposal).find((t) => t.isSelected)
}

export function getAllScheduledCalls (engagement) {
  const selectedTimeslotProposals = getProposals(engagement).filter((tp) => tp.status === 'ACCEPTED')
  if (!selectedTimeslotProposals || selectedTimeslotProposals.length === 0) {
    return []
  }

  const scheduledCalls = []
  selectedTimeslotProposals.forEach((proposal) => {
    const selectedTimeslot = getProposalTimeslots(proposal).find((t) => t.isSelected)
    if (selectedTimeslot) scheduledCalls.push(selectedTimeslot)
  })
  return scheduledCalls
}

export function getEngagementOrganizationName (engagement) {
  if (engagement.organization) {
    return engagement.organization.name
  }

  const matchingAdvisorOrg = getAdvisorMostRecentRelevantCompany(engagement.advisor)
  if (matchingAdvisorOrg) {
    if (matchingAdvisorOrg.organization) {
      return matchingAdvisorOrg.organization.name
    }
    return matchingAdvisorOrg.orgName
  }
  return ''
}

export function getEmailRelayLink (engagement) {
  return `engagement.${engagement.groupId}.relay@${__RELAY_HOSTNAME__}`
}

export function isEngagementEnded (engagement) {
  return engagement && (engagement.state === 'canceled' || engagement.state === 'closed')
}

export function isEngagementCallToday (engagement) {
  const relevantTimeslot = getEngagementMostRelevantTimeslot(engagement)
  if (!relevantTimeslot) return false
  return moment(relevantTimeslot.startTime).isSame(new Date(), 'day')
}

export function getCustomerPostCallFeedback (engagement) {
  if (engagement?.feedback) return engagement.feedback
  const timeslot = getEngagementMostRelevantTimeslot(engagement)
  if (!timeslot || !timeslot.customerPostCallFeedbackForm || timeslot.customerPostCallFeedbackForm.length === 0) return null
  return timeslot.customerPostCallFeedbackForm[0]
}

export function isCustomerFeedbackSubmitted (engagement) {
  if (engagement?.feedback) return true
  const relevantTimeslot = getEngagementMostRelevantTimeslot(engagement)
  return relevantTimeslot && relevantTimeslot.customerPostCallFeedbackForm && relevantTimeslot.customerPostCallFeedbackForm.length > 0
}

export function isUserFeedbackSubmitted (user, engagement) {
  if (user.userType === 'advisor') return Boolean(engagement.advisorSingleCallFeedback)
  if (user.userType === 'customer') return isCustomerFeedbackSubmitted(engagement)
  return false
}

export function getEngagementEndDate (engagement) {
  const relevantTimeslot = getEngagementMostRelevantTimeslot(engagement)
  const thirtyDaysAfterCall = relevantTimeslot && moment(relevantTimeslot.endTime).add(30, 'days')
  const isExpired = Date.parse(thirtyDaysAfterCall) < Date.parse(new Date())
  const isStateEnded = engagement.state === 'closed' || engagement.state === 'canceled'
  if (!isExpired && isStateEnded) return engagement.dateClosed || engagement.dateUpdated
  return thirtyDaysAfterCall
}

export function isEngagementActionable (engagement, user) {
  if (!engagement) return false
  const hasRelevantTimeslot = Boolean(getEngagementMostRelevantTimeslot(engagement))
  const hasCustomerSubmittedFeedback = isCustomerFeedbackSubmitted(engagement)
  const isParticipant = user.userType === 'customer' && parseInt(engagement.customerUser.id, 10) !== user.id
  if (engagement.state === 'canceled') return false
  if (engagement.state === 'closed' && !hasRelevantTimeslot) return false
  if (engagement.state === 'closed' && hasCustomerSubmittedFeedback) return false
  if (isParticipant && engagement.step === 'SUBMIT_FEEDBACK') return false
  return true
}

export function getEngagementApprovalRequest (engagementApprovalRequests, customerUserId, advisorId, status = null) {
  let filtered = engagementApprovalRequests.filter((ear) => ear.customerUserId === parseInt(customerUserId, 10) && ear.advisorId === parseInt(advisorId, 10))
  filtered = filtered.filter((ear) => ear.status !== 'canceled' && !ear.engagementId)
  if (status) filtered = filtered.filter((ear) => ear.status === status)
  if (filtered.length === 0) return null
  return filtered[0]
}

export function getEngagementApprovalRequestsForApprover (engagementApprovalRequests, approverUserId, advisorId = null, status = null) {
  let filtered = engagementApprovalRequests.filter((ear) => ear.approverUserId === parseInt(approverUserId, 10))
  filtered = filtered.filter((ear) => ear.status !== 'canceled' && !ear.engagementId)
  if (advisorId) filtered = filtered.filter((ear) => ear.advisorId === parseInt(advisorId, 10))
  if (status) filtered = filtered.filter((ear) => ear.status === status)
  const sorted = filtered.sort((a, b) => (a.dateCreated > b.dateCreated ? -1 : 1))
  return sorted
}

export function getDaysUntilProposalExpiration (engagement) {
  const daysRemaining = moment(engagement.dateCreated).add(7, 'days').diff(moment(), 'days') + 1
  if (daysRemaining < 0) return 0
  return daysRemaining
}

export async function addToCalendar (timeslotId, calendarType) {
  const result = await simpleHttpFetch(
    `${__API_HOST__}/invites/${timeslotId}/?type=${calendarType}`,
    {
      errorMessage: 'Could not get calendar invite.',
      json: calendarType === 'gcal'
    }
  )

  if (calendarType === 'gcal') {
    window.open(result.location)
  } else if (calendarType === 'ics') {
    const blob = await result.blob()
    downloadFile(blob, 'Emissary_Call.ics')
  }
}
