import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography
} from '@mui/material'

const AdvisorQuoteBox = ({ quote }) => (
  <Box
    sx={{
      bgcolor: 'brand.lightNavy',
      p: 3,
      borderRadius: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 1
    }}
  >
    <Typography variant="tiny">Advisor quote:</Typography>
    <Typography variant="bodyBold">{`"${quote}"`}</Typography>
  </Box>
)

AdvisorQuoteBox.propTypes = {
  quote: PropTypes.string.isRequired
}

export default AdvisorQuoteBox
