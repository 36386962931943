import moment from 'moment'

import * as ACTION_TYPE from './constants'

const INITIAL_STATE = {
  id: null,
  email: '',
  firstName: '',
  lastName: '',
  userType: ''
}

export default function sessionReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_TYPE.INITIALIZE_USER:
      return {
        ...state,
        id: action.user.id,
        email: action.user.email,
        firstName: action.user.first_name,
        lastName: action.user.last_name,
        userType: action.user.user_type,
        features: (action.user.features || []).filter((feature) => feature.enabled).map((feature) => ({
          flag: feature.feature,
          customerIds: (feature.customers || []).map((c) => c.id)
        })),
        timezone: action.user.timezone
      }
    case ACTION_TYPE.INITIALIZE_ADVISOR_USER: {
      let expertiseDomain = action.user.expertise_domain || ''
      if (expertiseDomain.startsWith('Other')) {
        expertiseDomain = 'Other'
      }

      return {
        ...state,
        id: action.user.id,
        uuid: action.user.uuid,
        email: action.user.email,
        firstName: action.user.first_name,
        lastName: action.user.last_name,
        fullName: action.user.full_name,
        phoneNumber: action.user.phone_number,
        timezone: action.user.timezone,
        jobTitle: action.user.job_title,
        customerName: action.user.customer_name,
        rate: action.user.rate,
        singleCallRate: action.user.single_call_rate,
        location: action.user.location,
        linkedinUrl: action.user.linkedin_url,
        isInNetwork: action.user.is_in_network,
        userType: action.user.user_type,
        isAvailable: action.user.is_available,
        cloaked: action.user.cloaked || state.cloaked,
        dateAvailable: action.user.date_available ? moment(action.user.date_available) : null,
        expertiseDomain,
        onboardingCompleted: action.user.onboarding_completed,
        termsAccepted: !!action.user.terms_date,
        features: (action.user.features || []).filter((feature) => feature.enabled).map((feature) => ({
          flag: feature.feature
        })),
        activeNotifications: action.user.activeNotifications || state.activeNotifications,
        status: action.user.status,
      }
    }
    case ACTION_TYPE.SET_AS_UNAVAILABLE:
      return {
        ...state,
        isAvailable: false,
        dateAvailable: moment(action.date)
      }
    case ACTION_TYPE.INITIALIZE_CUSTOMER_USER: {
      return {
        ...state,
        id: action.user.id,
        email: action.user.email,
        firstName: action.user.first_name,
        lastName: action.user.last_name,
        userType: action.user.user_type,
        customerId: action.user.customer_id,
        customerName: action.user.customer_name,
        samlIdpId: action.user.saml_idp_id,
        oidcProvider: action.user.oidc_provider,
        roleType: action.user.role_type,
        cloaked: action.user.cloaked || state.cloaked,
        onboardingCompleted: action.user.onboarding_completed,
        features: (action.user.features || []).filter((feature) => feature.enabled).map((feature) => ({
          flag: feature.feature
        })),
        termsAccepted: !!action.user.terms_date,
        isApprover: action.user.divisions.some((d) => d.approval_required && d.approver_user_id === action.user.id),
        isUnlimitedByAccount: action.user.unlimited_by_account,
        activeNotifications: action.user.activeNotifications || state.activeNotifications,
        status: action.user.status,
        timezone: action.user.timezone,
      }
    }
    case ACTION_TYPE.LOGOUT_USER:
      return INITIAL_STATE
    default:
      return state
  }
}
