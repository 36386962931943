const constants = {
  ROWS_PER_PAGE_OPTIONS: [10, 25, 50],
  DEFAULT_ROWS_PER_PAGE: 10,
  ADMIN_DEFAULT_ROWS_PER_PAGE: 25,
  MYSQL_MAX_INT: 2147483647,
  ENGAGEMENT_PROPOSAL_EXPIRATION_TIME_IN_DAYS: 7,
  TWO_OH_ROWS_PER_PAGE_OPTIONS: [20, 40, 60],
  SMALL_TABLE_ROWS_PER_PAGE_OPTIONS: [10, 20, 30],
  MAX_FEEDBACK_SCORE: 7,
  DEFAULT_DATAGRID_ROWS_PER_PAGE: 20,
  DEFAULT_DATAGRID_ROWS_PER_PAGE_OPTIONS: [20, 50, 100],
  MIN_MESSAGE_LENGTH: 6,
  TYPEFORM_ADMIN_URL: 'https://admin.typeform.com',
  DEFAULT_EXPIRATION_DAYS: 45,
}

export default constants
