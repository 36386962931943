import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card,
  Link,
  Slider,
  Tooltip,
  Typography
} from '@mui/material'

import { HelpCircle } from 'icons'

const WhiskerCard = ({
  chartLow,
  chartMid,
  chartHigh,
  textMin,
  textMax,
  chartMin,
  chartMax,
  title,
}) => {
  const getChartValues = () => {
    // add some width to the chart if there is no range
    if (chartLow === chartHigh) {
      return [chartLow - 0.1, chartLow, chartHigh + 0.1]
    }
    return [chartLow, chartMid, chartHigh]
  }
  const MinMaxText = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
      <Box textAlign="left" sx={{ mr: 1 }}>
        <Typography variant="tiny">{textMin}</Typography>
      </Box>
      <Box textAlign="right" sx={{ ml: 1 }}>
        <Typography variant="tiny">{textMax}</Typography>
      </Box>
    </Box>
  )

  const AboutTooltip = () => {
    const tooltipText = `
    Advisors rate each topic on a 1 to 7 scale. The orange bar illustrates the range 
    of responses. These charts show where the account typically leans on key topics, 
    and potential differences across business units. Use the quotes and summaries to 
    understand the ‘why’ behind the ratings.
    `
    return (
      <Link
        variant="tinyBold"
        color="neutral.darkGrey"
        sx={{ display: 'flex', alignItems: 'center', mt: 2 }}
      >
        <Tooltip
          title={tooltipText}
          cursor="pointer"
          placement="top"
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <HelpCircle sx={{ height: '16px', width: '16px', color: 'neutral.darkGrey' }} />
            <Typography variant="tinyBold" color="neutral.darkGrey" sx={{ ml: 0.5 }}>
              About this chart
            </Typography>
          </Box>
        </Tooltip>
      </Link>
    )
  }
  return (
    <Card sx={{ p: 3, bgcolor: 'brand.lightNavy', borderRadius: 1, border: 'none', flexBasis: '50%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body1" sx={{ mb: 0.5 }}>
          {title}
        </Typography>
        <Slider
          disabled
          min={chartMin}
          max={chartMax}
          defaultValue={getChartValues()}
          sx={{
            '& .MuiSlider-rail': { height: '8px', color: 'brand.paleSlate', opacity: 1 },
            '& .MuiSlider-track': { height: '8px', color: 'brand.orange', border: 'none' },
            '& .MuiSlider-thumb': { display: 'none' },
          }}
        />
        {textMin && textMax && <MinMaxText />}
        <AboutTooltip />
      </Box>

    </Card>
  )
}

WhiskerCard.defaultProps = {
  textMin: null,
  textMax: null,
  chartMin: 1,
  chartMax: 7,
  title: null
}

WhiskerCard.propTypes = {
  chartLow: PropTypes.number.isRequired,
  chartMid: PropTypes.number.isRequired,
  chartHigh: PropTypes.number.isRequired,
  textMin: PropTypes.string,
  textMax: PropTypes.string,
  chartMin: PropTypes.number,
  chartMax: PropTypes.number,
  title: PropTypes.string,
}

export default WhiskerCard
