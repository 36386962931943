import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'
import {
  Calendar
} from 'icons'
import {
  formatDate
} from 'utils-em'
import { AccountPageContext } from '../AccountPageContext'

const LastUpdatedTag = ({ isAbbreviated }) => {
  const { accountPage } = React.useContext(AccountPageContext)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
      <Calendar sx={{ fontSize: 16 }} />
      <Typography variant="body1">
        {`${isAbbreviated ? '' : 'Last updated '}${formatDate(accountPage.mostRecentUpdateDate, { includeYear: true })}`}
      </Typography>
    </Box>
  )
}

LastUpdatedTag.defaultProps = {
  isAbbreviated: false
}

LastUpdatedTag.propTypes = {
  isAbbreviated: PropTypes.bool
}

export default LastUpdatedTag
