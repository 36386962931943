import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Typography,
  Paper,
  Chip
} from '@mui/material'

import AirlineSeatReclineNormal from '@mui/icons-material/AirlineSeatReclineNormal'
import AccountCircle from '@mui/icons-material/AccountCircle'
import AllInclusive from '@mui/icons-material/AllInclusive'
import CalendarToday from '@mui/icons-material/CalendarToday'
import CheckCircle from '@mui/icons-material/CheckCircle'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import Close from '@mui/icons-material/Close'
import Search from '@mui/icons-material/Search'
import Group from '@mui/icons-material/Group'
import Filter1 from '@mui/icons-material/Filter1'
import FilterTiltShift from '@mui/icons-material/FilterTiltShift'
import People from '@mui/icons-material/People'
import Hail from '@mui/icons-material/Hail'
import { StickyTextField, StickySelectField } from 'components'

import { buildJsonApiFilter } from 'utils-em'

import CustomerAutocomplete from '../../../../../../components/CustomerAutocomplete'

const styles = {
  paper: {
    padding: '0rem 1rem 2rem'
  },
  bottomControls: {
    paddingTop: '1rem'
  },
  heading: {
    paddingBottom: '1rem'
  },
  inputField: {
    margin: '0.5rem 0rem'
  },
  formControl: {
    margin: '0.5rem 0rem'
  }
}

const Filters = ({ onFilterUpdate }) => {
  const [filters, setFilters] = useState({
    'organization.name': { value: '', or: ['organization.name'] },
    'customerUser.customer.id': { value: '' },
    state: { value: '' },
    'advisor.fullName': { value: '' },
    'customerUser.fullName': { value: '', or: ['customerUser.fullName', 'participants__any.user.fullName'] },
    'customerUser.divisions__any.name': { value: '', or: ['customerUser.divisions__any.name', 'participants__any.user.divisions__any.name'] },
    id__eq: { value: '' }
  })

  useEffect(() => {
    onFilterUpdate(Object.keys(filters).map((f) => filters[f].filter).filter((val) => !!val))
  }, [filters])

  const handleSubmit = (e) => {
    e.preventDefault()
    onFilterUpdate(Object.keys(filters).map((f) => filters[f].filter).filter((val) => !!val))
  }

  const handleSearchFilter = (filterName, value) => {
    let newFilters = value ? buildJsonApiFilter(filterName, value) : null
    if (filters[filterName].or && value) {
      newFilters = {
        or: filters[filterName].or.map((condition) => buildJsonApiFilter(condition, value))
      }
      newFilters.or.push(buildJsonApiFilter(filterName, value))
    }
    setFilters((prevState) => ({
      ...prevState,
      [filterName]: {
        value,
        or: filters[filterName].or,
        filter: newFilters
      }
    }))
  }

  return (
    <Paper sx={{ p: '1em' }}>
      <form onSubmit={handleSubmit}>
        <Typography sx={{ ...styles.heading }} variant="h6" component="div">
          Filters
        </Typography>
        <StickyTextField
          stickyKey="organization.name"
          fullWidth
          size="small"
          margin="dense"
          label="Account"
          variant="outlined"
          value={filters['organization.name'].value}
          onChange={(event) => handleSearchFilter('organization.name', event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            )
          }}
        />
        <FormControl sx={{ ...styles.formControl }} fullWidth variant="outlined" size="small">
          <InputLabel id="state-select-label">State</InputLabel>
          <StickySelectField
            stickyKey="engagement.state"
            labelId="state-select-label"
            id="role-select"
            label="State"
            startAdornment={(
              <InputAdornment position="start">
                <FilterTiltShift />
              </InputAdornment>
            )}
            variant="outlined"
            value={filters.state.value}
            onChange={(event) => handleSearchFilter('state', event.target.value)}
          >
            <MenuItem value="">
              <Chip size="small" label="Any" icon={<AllInclusive />} />
            </MenuItem>
            <MenuItem value="scheduled">
              <Chip size="small" label="Scheduled" icon={<CalendarToday />} />
            </MenuItem>
            <MenuItem value="active">
              <Chip size="small" label="Active" icon={<CheckCircle />} />
            </MenuItem>
            <MenuItem value="canceled">
              <Chip size="small" label="Canceled" icon={<RemoveCircle />} />
            </MenuItem>
            <MenuItem value="closed">
              <Chip size="small" label="Closed" icon={<Close />} />
            </MenuItem>
            <MenuItem value="proposed">
              <Chip size="small" label="Proposed" icon={<Hail />} />
            </MenuItem>
            <MenuItem value="awaiting-advisor">
              <Chip size="small" label="Awaiting Advisor" icon={<AirlineSeatReclineNormal />} />
            </MenuItem>
            <MenuItem value="proposal-canceled">
              <Chip size="small" label="Proposal canceled" icon={<Close />} />
            </MenuItem>
          </StickySelectField>
        </FormControl>
        <StickyTextField
          stickyKey="engagement.advisor"
          fullWidth
          size="small"
          magin="dense"
          label="Advisor"
          variant="outlined"
          value={filters['advisor.fullName'].value}
          onChange={(event) => handleSearchFilter('advisor.fullName', event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            )
          }}
        />
        <StickyTextField
          stickyKey="engagement.customerUsers"
          sx={{ ...styles.inputField }}
          fullWidth
          size="small"
          magin="dense"
          label="Customer Users"
          variant="outlined"
          value={filters['customerUser.fullName'].value}
          onChange={(event) => handleSearchFilter('customerUser.fullName', event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <People />
              </InputAdornment>
            )
          }}
        />
        <CustomerAutocomplete
          sx={{ ...styles.inputField }}
          filterName="customerUser.customer.name"
          size="small"
          margin="dense"
          label="Customer"
          variant="outlined"
          multiple={false}
          onChange={(_, customer) => handleSearchFilter('customerUser.customer.id', customer ? customer.id : null)}
        />
        <StickyTextField
          stickyKey="engagement.divisions"
          sx={{ ...styles.inputField }}
          fullWidth
          size="small"
          magin="dense"
          label="Divisions"
          variant="outlined"
          value={filters['customerUser.divisions__any.name'].value}
          onChange={(event) => handleSearchFilter('customerUser.divisions__any.name', event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Group />
              </InputAdornment>
            )
          }}
        />
        <StickyTextField
          stickyKey="engagement.id"
          sx={{ ...styles.inputField }}
          fullWidth
          size="small"
          magin="dense"
          label="ID"
          variant="outlined"
          value={filters.id__eq.value}
          onChange={(event) => handleSearchFilter('id__eq', event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Filter1 />
              </InputAdornment>
            )
          }}
        />
        <Box sx={{ ...styles.bottomControls }}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            endIcon={<Search />}
            type="submit"
            onClick={handleSubmit}
          >
            Search
          </Button>
        </Box>
      </form>
    </Paper>
  )
}

Filters.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired
}

export default Filters
