import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Drawer,
  Divider,
  IconButton,
  Typography
} from '@mui/material'

import {
  Calendar,
  Clipboard
} from 'icons'
import {
  BookmarkAccountButton
} from 'components'
import {
  useEmissaryTheme
} from 'hooks'
import {
  copyToClipboardWithAlert,
  formatDate
} from 'utils-em'

import useAccountPageContext from '../hooks/useAccountPageContext'
import Logo from './Logo'
import BookACallButton from './BookACallButton'

const StickyBar = ({ isOpen }) => {
  const { organization, mostRecentUpdateDate, savedAccountState } = useAccountPageContext()

  const {
    isSmOrSmaller,
    pagePaddingX
  } = useEmissaryTheme()

  const DateUpdated = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
      <Calendar sx={{ fontSize: 16 }} />
      <Typography variant="body1">
        {`${formatDate(mostRecentUpdateDate, { includeYear: true })}`}
      </Typography>
    </Box>
  )

  const CopyToClipboard = () => (
    <IconButton
      onClick={() => copyToClipboardWithAlert(
        window.location.href,
        'Link copied to clipboard.'
      )}
      sx={{ '&:hover': { backgroundColor: 'primary.lightest' } }}
    >
      <Clipboard sx={{ color: 'primary.main' }} />
    </IconButton>
  )

  return (
    <Drawer
      anchor="top"
      open={isOpen}
      variant="persistent"
      PaperProps={{
        sx: {
          borderBottom: 'none !important'
        }
      }}
    >
      <Box
        sx={{
          height: '68px', // 64px bar with 4px border-bottom
          borderBottom: (theme) => `4px SOLID ${theme.palette.brand.paleSlate}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {/* Left side */}
        <Box sx={{ ml: isSmOrSmaller ? 2 : pagePaddingX, display: 'flex', gap: isSmOrSmaller ? 1 : 2, alignItems: 'center' }}>
          <Logo isSmall />
          {!isSmOrSmaller && (
            <>
              <Divider orientation="vertical" flexItem sx={{ borderRightWidth: '4px', height: '40px' }} />
              <DateUpdated />
            </>
          )}
        </Box>
        {/* Right side */}
        <Box sx={{ mr: isSmOrSmaller ? 2 : pagePaddingX, display: 'flex', gap: isSmOrSmaller ? 1 : 2, alignItems: 'center' }}>
          <BookmarkAccountButton
            orgId={organization?.id}
            {...savedAccountState}
            iconOnly
          />
          <CopyToClipboard />
          <BookACallButton isResponsive organizationId={organization?.id} hideMessage />
        </Box>
      </Box>
    </Drawer>
  )
}

StickyBar.defaultProps = {
  isOpen: false
}

StickyBar.propTypes = {
  isOpen: PropTypes.bool
}

export default StickyBar
