import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { OrganizationSelectorTwo } from 'components'
import { capitalize, difference } from 'lodash'
import {
  Box,
  Chip,
  Typography,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Link,
  Skeleton,
  useTheme
} from '@mui/material'
import {
  closeDialog,
  customAlert,
  httpResponseAlert
} from 'utils-em'
import {
  useJsonAPIGetAll,
  useSessionUser
} from 'hooks'

import { JsonAPIActions } from 'store'

import { CloseCircle } from 'icons'

const API_HOST = __API_HOST__

const ManageAccounts = ({ userId, onSubmitCallback, reload, onboarding, sortBy }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { isUnlimitedByAccount } = useSessionUser()
  const advisorRequestFilters = [
    { name: 'status', op: 'eq', val: 'open' },
    {
      name: 'customerUsers',
      op: 'any',
      val: {
        name: 'id',
        op: 'eq',
        val: userId
      }
    }
  ]
  const { objects: initialAdvisorRequests, loaded } = useJsonAPIGetAll(
    'advisorRequests',
    {
      filters: advisorRequestFilters,
      sortBy,
      include: ['account', 'account.organization', 'customerUsers']
    }
  )
  const [advisorRequests, setAdvisorRequests] = React.useState([])
  const [saveDisabled, setSaveDisabled] = React.useState(true)

  const handleSave = async () => {
    setSaveDisabled(true)
    try {
      const advisorRequestsToSave = advisorRequests.map(({ customerUsers, ...keepAttrs }) => keepAttrs)
      const resp = await fetch(
        `${API_HOST}/v1/users/${userId}/primary-advisor-requests`,
        {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'PUT',
          body: JSON.stringify({ advisorRequests: advisorRequestsToSave })
        }
      )
      const body = await resp.json()
      dispatch(JsonAPIActions.populateJsonApi(body, { type: 'advisorRequests' }))
      httpResponseAlert(resp, {
        success: 'Accounts list successfully saved.'
      })
      if (reload) { reload() }
    } catch (error) {
      customAlert('Unable to update accounts. Please contact support.', true)
    }
    if (onSubmitCallback) { onSubmitCallback() }
  }
  const filterToDistinctAccounts = (ars) => (
    ars.reduce((distinctList, ar) => {
      if (!distinctList.find((a) => a.account.organizationId === ar.account.organizationId)) {
        distinctList.push(ar)
      }
      return distinctList
    }, [])
  )

  const distinctOrgInitialAdvisorReqeuests = filterToDistinctAccounts(initialAdvisorRequests)

  const handleDelete = (orgId) => {
    setAdvisorRequests([...advisorRequests.filter((ar) => ar.account.organizationId !== orgId)])
    setSaveDisabled(false)
  }

  const handleNewOrg = (newOrg) => {
    if (newOrg && !advisorRequests.some((ar) => ar.account.organizationId === newOrg.id)) {
      const newAdvisorRequest = {
        status: 'open',
        account: {
          name: newOrg.name,
          organizationId: newOrg.id,
          organization: { name: newOrg.name }
        }
      }
      setAdvisorRequests([...advisorRequests, newAdvisorRequest])
      setSaveDisabled(false)
    }
  }

  React.useEffect(() => {
    setAdvisorRequests(initialAdvisorRequests)
    if (onboarding && initialAdvisorRequests.length) { setSaveDisabled(false) }
  }, [loaded])

  if (!loaded) { return (<Skeleton variant="rounded" height="100px" />) }

  const addedARs = difference(advisorRequests.map((ar) => ar.account.organizationId), distinctOrgInitialAdvisorReqeuests.map((ar) => ar.account.organizationId))
  const deletedARs = difference(distinctOrgInitialAdvisorReqeuests.map((ar) => ar.account.organizationId), advisorRequests.map((ar) => ar.account.organizationId))

  const modifications = []
  let modificationsText = ''
  if (addedARs.length) modifications.push(`added ${addedARs.length} account${addedARs.length > 1 ? 's' : ''}`)
  if (deletedARs.length) modifications.push(`removed ${deletedARs.length} account${deletedARs.length > 1 ? 's' : ''}`)
  if (modifications.length) modificationsText = capitalize(modifications.join(', '))
  return (
    <>
      { !onboarding && <DialogTitle>Manage accounts</DialogTitle> }
      <DialogContent sx={{ pl: onboarding ? 0 : 3, pb: onboarding ? 0 : 3 }}>
        <Box>
          {onboarding ? (
            <Typography variant="body1">
              Emissary provides insights to the world&apos;s largest and most complex companies across the Fortune 1k and Global 2k.
              In order to personalize your Emissary experience, please select a few companies from the list of accounts that Emissary supports.
              You can edit this list at any time from your profile.
            </Typography>
          ) : (
            <Typography variant="body1">
              Add an Emissary account to your profile to receive notifications for data updates
              {isUnlimitedByAccount ? '. ' : ' and new advisors.'}
              For a full list of accounts that Emissary supports, check out
              {' '}
              <Link href="/c/accounts/browse/alphabetical" rel="noreferrer" target="_blank">
                this page
              </Link>
              .
            </Typography>
          )}
          <Box sx={{ mt: 2 }}>
            <OrganizationSelectorTwo
              textFieldParams={{
                variant: 'outlined',
                label: '',
                placeholder: 'Search accounts',
                margin: 'dense'
              }}
              noOptionsText="Enter account name"
              defaultValue={null}
              onSelected={(organization) => handleNewOrg(organization)}
              clearOnSelected
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            {advisorRequests.sort((a, b) => a.account?.organization?.name.localeCompare(b.account.name)).map((ar) => {
              const wasAdded = addedARs.includes(ar.account.organizationId)
              if (!ar.account.organization) return null
              return (
                ar.account.organization && (
                  <Chip
                    key={ar.id}
                    sx={{
                      margin: 0.5,
                      color: wasAdded ? 'success.dark' : 'neutral.black',
                      bgcolor: wasAdded ? 'success.lightest' : 'neutral.offWhite'
                    }}
                    size="small"
                    label={ar.account.organization?.name}
                    deleteIcon={<CloseCircle style={{ color: wasAdded ? theme.palette.success.dark : theme.palette.neutral.black }} />}
                    onDelete={() => handleDelete(ar.account.organizationId)}
                  />
                )
              )
            })}
          </Box>
          {modificationsText.length ? (
            <Typography
              variant="captionBold"
              color="neutral.darkGrey"
              sx={{ mt: 2, display: 'inline-block' }}
            >
              {modificationsText}
            </Typography>
          ) : null}
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: onboarding ? 'left' : 'right', p: onboarding ? 0 : 3 }}>
        { onboarding ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 3, alignItems: 'center' }}>
            <Button type="submit" variant="contained" disabled={saveDisabled || !advisorRequests.length} onClick={handleSave} sx={{ mr: 2 }}>Submit</Button>
            <Typography variant="caption" color="neutral.darkGrey">Step 2 of 2</Typography>
          </Box>
        )
          : (
            <>
              <Button variant="text" onClick={closeDialog}>Cancel</Button>
              <Button
                type="submit"
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={saveDisabled}
              >
                Save
              </Button>
            </>
            )}
      </DialogActions>
    </>
  )
}
ManageAccounts.defaultProps = {
  onSubmitCallback: null,
  reload: null,
  onboarding: false,
  sortBy: ''
}

ManageAccounts.propTypes = {
  userId: PropTypes.number.isRequired,
  onSubmitCallback: PropTypes.func,
  reload: PropTypes.func,
  onboarding: PropTypes.bool,
  sortBy: PropTypes.string
}
export default ManageAccounts
