import React from 'react'

import {
  Box,
  Typography
} from '@mui/material'

import {
  Calendar
} from 'icons'
import {
  formatDate
} from 'utils-em'
import useAccountPageContext from '../hooks/useAccountPageContext'

const SectionHeader = () => {
  const { activeSectionTitle, mostRecentUpdateDate } = useAccountPageContext()
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
      <Typography variant="h4">{activeSectionTitle}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
        <Calendar sx={{ fontSize: 16 }} />
        <Typography variant="body1">
          {`Last updated ${formatDate(mostRecentUpdateDate, { includeYear: true })}`}
        </Typography>
      </Box>
    </Box>
  )
}

export default SectionHeader
