import React from 'react'
import PropTypes from 'prop-types'
import {
  useLocation
} from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  Typography
} from '@mui/material'

import {
  useEmissaryTheme
} from 'hooks'
import {
  Clipboard,
  Mail
} from 'icons'
import {
  CustomerAccessControl,
  RelayMessageDialog
} from 'components'
import {
  copyToClipboardWithAlert,
  openDialog,
} from 'utils-em'

const EngagementHeader = ({ title, description, relay, sharable, actions }) => {
  const { isSmOrSmaller, verticalSpacing, pagePaddingX } = useEmissaryTheme()
  const { pathname } = useLocation()

  const TopButtonGroup = () => {
    // eslint-disable-next-line react/prop-types
    const TopButton = ({ icon, children, ...rest }) => (
      <Button variant="text" sx={{ m: 0, minWidth: 0 }} {...rest}>
        {children}
      </Button>
    )
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <>
          &nbsp;
          {/* Empty node to force flex items to right even if button is hidden */}
        </>
        <Box sx={{ display: 'flex', gap: isSmOrSmaller ? 1 : 2 }}>
          {relay && (
            <TopButton startIcon={<Mail />} onClick={() => openDialog(<RelayMessageDialog relayLink={relay} />)}>
              {isSmOrSmaller ? '' : 'Email relay'}
            </TopButton>
          )}
          {sharable && (
            <CustomerAccessControl>
              <TopButton startIcon={<Clipboard />} onClick={() => copyToClipboardWithAlert(`${__APP_HOST__}${pathname}`)}>
                {isSmOrSmaller ? '' : 'Copy link to share'}
              </TopButton>
            </CustomerAccessControl>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Box id="engagement-header">
      <Box sx={{ px: 3, py: 1.5 }}>
        <TopButtonGroup />
      </Box>
      <Box sx={{ px: 3, py: 1.5 }}>
        <Typography variant="h1">
          {title}
        </Typography>
        <Typography variant="h3">
          {description}
        </Typography>
      </Box>
      {actions && (
        <Box sx={{ px: 3, py: 1.5 }}>
          {actions}
        </Box>
      )}
      <Divider sx={{
        mx: -pagePaddingX,
        mt: verticalSpacing,
        borderColor: 'brand.paleSlate',
        borderBottomWidth: '4px'
      }}
      />
    </Box>
  )
}

EngagementHeader.defaultProps = {
  relay: null,
  sharable: false,
  actions: null
}

EngagementHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  relay: PropTypes.string,
  sharable: PropTypes.bool,
  actions: PropTypes.node
}

export default EngagementHeader
