/* eslint-disable react/prop-types */
import React from 'react'
import {
  Box,
  Link,
  Typography
} from '@mui/material'

import {
  FileWithText,
  Information,
  Lightbulb
} from 'icons'

import { getScoredLabel } from '../fixtures/labels'
import useAccountPageContext from '../hooks/useAccountPageContext'
import AdvisorResponses from '../components/AdvisorResponses'
import BulletedParagraph from '../components/BulletedParagraph'
import SectionHeader from '../components/SectionHeader'
import WhiskerCard from '../components/WhiskerCard'

const WhiskerSection = () => {
  const {
    activeSectionKey,
    activeSectionData,
    activeSectionQuestionText,
    activeSurveyResults,
    hasCompletedSurveyResults,
    activeSectionResponses,
    organization
  } = useAccountPageContext()
  const [isExpanded, setIsExpanded] = React.useState(false)
  const { min, avg, max } = activeSurveyResults || {}
  const hasSectionChartData = Boolean(min && avg && max)
  const {
    titleLow,
    titleHigh,
    sentence
  } = getScoredLabel(activeSectionKey, organization?.name, avg) || {}

  // Collapse the section when the active section changes
  // Open by default if there are no advisor responses
  React.useEffect(() => {
    setIsExpanded(!activeSectionResponses?.length)
  }, [activeSectionKey, activeSectionResponses?.length])

  const ColumnBox = ({ children, ...rest }) => (
    <Box display="flex" flexDirection="column" gap={3} {...rest}>
      {children}
    </Box>
  )

  const WeAskedAdvisors = () => (
    <ColumnBox gap={1}>
      <Typography variant="tiny">We asked advisors:</Typography>
      <Typography variant="h5">{activeSectionQuestionText}</Typography>
      <ColumnBox />
    </ColumnBox>
  )

  const LightbulbText = () => {
    if (!activeSectionData?.title) return null
    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Lightbulb />
        <Typography variant="bodyBold">{activeSectionData?.title}</Typography>
      </Box>
    )
  }

  const ExpandButton = () => (
    <Link
      variant="tinyBold"
      color="neutral.darkGrey"
      onClick={() => setIsExpanded(true)}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <FileWithText sx={{ height: '16px', width: '16px', color: 'neutral.darkGrey' }} />
      <Typography variant="tinyBold" color="neutral.darkGrey" sx={{ ml: 0.5 }}>
        Expand Emissary's writeup
      </Typography>
    </Link>
  )

  const CollapseButton = () => (
    <Box
      onClick={() => setIsExpanded(false)}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Information sx={{ height: '16px', width: '16px', color: 'neutral.darkGrey' }} />
      <Typography variant="tiny" color="neutral.darkGrey" sx={{ ml: 0.5 }}>
        This was written by a writer based on our advisors’ responses
      </Typography>
      <Link variant="tinyBold" color="neutral.darkGrey" sx={{ ml: 2 }}>
        Collapse
      </Link>
    </Box>

  )

  return (
    <ColumnBox>
      <ColumnBox sx={{ pl: 3 }}>
        <SectionHeader />
        <WeAskedAdvisors />
        <LightbulbText />
        {isExpanded && <BulletedParagraph paragraph={activeSectionData?.paragraph} />}
        {isExpanded ? <CollapseButton /> : <ExpandButton />}
      </ColumnBox>
      {hasCompletedSurveyResults && hasSectionChartData && (
        <WhiskerCard
          chartLow={min}
          chartMid={avg}
          chartHigh={max}
          textMin={titleLow}
          textMax={titleHigh}
          title={sentence}
        />
      )}
      <ColumnBox sx={{ pl: 3 }}>
        <AdvisorResponses />
      </ColumnBox>
    </ColumnBox>

  )
}

export default WhiskerSection
