import React from 'react'
import PropTypes from 'prop-types'
import { Box, Chip, Typography, useTheme } from '@mui/material'
import { Badge } from 'icons'

const BadgeChip = ({ text, color, size, textSize, noDot, ...rest }) => {
  const { palette } = useTheme()

  const getColors = () => {
    if (color === 'grey') return [palette.neutral.darkGrey, palette.neutral.offWhite]
    if (color === 'green') return [palette.success.main, palette.success.lightest]
    if (color === 'yellow') return [palette.warning.main, palette.warning.lightest]
    if (color === 'red') return [palette.error.main, palette.error.lightest]
    if (color === 'blue') return [palette.primary.main, palette.primary.lightest]
    if (color === 'teal') return [palette.brand.darkTeal, palette.brand.paleTeal]
    if (color === 'orange') return [palette.brand.darkOrange, palette.brand.paleOrange]
    if (color === 'white') return [palette.neutral.black, palette.neutral.white]

    // default color is blue
    return [palette.primary.main, palette.primary.lightest]
  }
  const [badgeColor, chipColor] = getColors()

  return (
    <Box {...rest}>
      <Chip
        icon={!noDot && <Badge fill={badgeColor} />}
        label={<Typography variant={textSize} color={badgeColor}>{text}</Typography>}
        size={size}
        sx={{ bgcolor: chipColor }}
      />
    </Box>

  )
}

BadgeChip.defaultProps = {
  color: null,
  size: 'large',
  textSize: 'bodyBold',
  noDot: false
}

BadgeChip.propTypes = {
  text: PropTypes.string.isRequired,
  textSize: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  noDot: PropTypes.bool
}

export default BadgeChip
