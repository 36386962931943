const labels = [
  {
    key: 'techBudget', // these keys should match the topic keys
    sentence: "Our advisors say that {{ account }}'s tech budget is {{ phrase }}.",
    phraseLow: 'significantly shrinking',
    phraseMed: 'relatively steady',
    phraseHigh: 'significantly growing',
    titleLow: 'Significantly shrinking',
    titleMed: 'Steady',
    titleHigh: 'Significantly growing'
  },
  {
    key: 'riskTolerance',
    sentence: "Advisors describe {{ account }}'s risk tolerance as {{ phrase }}.",
    phraseLow: 'extremely risk-averse',
    phraseMed: 'balanced',
    phraseHigh: 'extremely innovative',
    titleLow: 'Extremely risk-averse',
    titleMed: 'Balanced',
    titleHigh: 'Extremely innovative'
  },
  {
    key: 'makeOrBuy',
    sentence: '{{ account }} leans toward {{ phrase }}.',
    phraseLow: 'building tech in-house',
    phraseMed: 'a blended approach to buy/build decisions',
    phraseHigh: 'buying tech externally',
    titleLow: 'Strong make in-house',
    titleMed: 'Blended approach',
    titleHigh: 'Strong buy external'
  },
  {
    key: 'digitalTransformation',
    sentence: "According to advisors, {{ account }}'s digital transformation {{ phrase }}.",
    phraseLow: 'has made minimal progress',
    phraseMed: 'has made some progress',
    phraseHigh: 'is highly mature',
    titleLow: 'Minimal progress',
    titleMed: 'Some progress',
    titleHigh: 'Highly mature'
  },
  {
    key: 'techStrategy',
    sentence: 'Advisors say that the technology strategy at {{ account }} is {{ phrase }}.',
    phraseLow: 'led by BUs',
    phraseMed: 'a collaboration between Corp. and BUs',
    phraseHigh: 'mostly centralized',
    titleLow: 'Led by BUs',
    titleMed: 'Collaborative approach',
    titleHigh: 'Led centrally'
  },
  {
    key: 'cloudApproach',
    sentence: 'The approach at {{ account }} for cloud solutions is {{ phrase }}.',
    phraseLow: 'on-premise first',
    phraseMed: 'a hybrid approach',
    phraseHigh: 'cloud-first',
    titleLow: 'On-premise first',
    titleMed: 'Hybrid approach',
    titleHigh: 'Cloud-first'
  },
  {
    key: 'openSource',
    sentence: 'Advisors note that {{ account }} {{ phrase }} open-source solutions.',
    phraseLow: 'is not supportive of',
    phraseMed: 'is somewhat supportive of',
    phraseHigh: 'prefers',
    titleLow: 'Not supportive',
    titleMed: 'Somewhat supportive',
    titleHigh: 'Prefers open-source'
  },
  {
    key: 'techApproach',
    sentence: 'According to advisors, {{ account }} {{ phrase }}.',
    phraseLow: 'favors broad solutions',
    phraseMed: 'has no preference between broad and point solutions',
    phraseHigh: 'favors best-of-breed',
    titleLow: 'Favors broad solutions',
    titleMed: 'No strong preference',
    titleHigh: 'Favors best-of-breed'
  },
  {
    key: 'cyberResiliency',
    sentence: "Advisors say {{ account }}'s approach to cyber resiliency is {{ phrase }}.",
    phraseLow: 'not at all mature',
    phraseMed: 'somewhat mature',
    phraseHigh: 'very mature',
    titleLow: 'Not at all mature',
    titleMed: 'Somewhat mature',
    titleHigh: 'Very mature'
  },
  {
    key: 'budgetProcess',
    sentence: 'Our advisors describe the budgeting process at {{ account }} as {{ phrase }}.',
    phraseLow: 'extremely rigid',
    phraseMed: 'structured with exceptions',
    phraseHigh: 'highly flexible',
    titleLow: 'Extremely rigid',
    titleMed: 'Conditional',
    titleHigh: 'Highly flexible'
  },
  {
    key: 'influence',
    sentence: 'Procurement at {{ account }} has {{ phrase }}.',
    phraseLow: 'minimal influence',
    phraseMed: 'some influence',
    phraseHigh: 'powerful influence',
    titleLow: 'Minimal influence',
    titleMed: 'Some influence',
    titleHigh: 'Powerful influence'
  },
  {
    key: 'artificialIntelligence',
    sentence: 'Advisors note that {{ account }} {{ phrase }} AI solutions.',
    phraseLow: 'is not using/researching',
    phraseMed: 'has some adoption of',
    phraseHigh: 'has wide scale adoption of',
    titleLow: 'Not using/researching',
    titleMed: 'Some adoption',
    titleHigh: 'Wide scale adoption'
  }
]

const ranges = [
  {
    titleKey: 'titleLow',
    phraseKey: 'phraseLow',
    minScore: 0, // inclusive of 1
    maxScore: 2.5 // inclusive
  },
  {
    titleKey: 'titleMed',
    phraseKey: 'phraseMed',
    minScore: 2.5,
    maxScore: 5.5 // inclusive
  },
  {
    titleKey: 'titleHigh',
    phraseKey: 'phraseHigh',
    minScore: 5.5,
    maxScore: 7 // inclusive
  }

]

export const getScoredLabel = (key, account, score) => {
  console.log([key, account, score])
  const label = labels.find((l) => l.key === key)
  const range = ranges.find((r) => score > r.minScore && score <= r.maxScore)

  const phrase = label?.[range?.phraseKey]
  const title = label?.[range?.titleKey]

  // replace account and phrase in sentence
  const sentence = label?.sentence
    .replace('{{ account }}', account)
    .replace('{{ phrase }}', phrase)

  return { ...label, title, sentence }
}

export default labels
