import WhiskerSection from './WhiskerSection'
import TextOnlySection from './TextOnlySection'
import SummarySection from './SummarySection'
import AccountSellingTips from './AccountSellingTips'
import ArtificialIntelligence from './ArtificialIntelligence'
import ChatbotWrapper from './ChatbotWrapper'
import Coaching from './Coaching'
import TechAlignment from './TechAlignment'

const sectionComponents = [
  // Account Selling Tips
  { key: 'sellingTips', Component: AccountSellingTips },
  // Tech Spend Drivers
  { key: 'propensityToBuy', Component: SummarySection },
  { key: 'techBudget', Component: WhiskerSection },
  { key: 'riskTolerance', Component: WhiskerSection },
  { key: 'makeOrBuy', Component: WhiskerSection },
  { key: 'artificialIntelligence', Component: ArtificialIntelligence },
  { key: 'digitalTransformation', Component: WhiskerSection },
  // Tech Approach
  { key: 'techPreferences', Component: SummarySection },
  { key: 'techStrategy', Component: WhiskerSection },
  { key: 'cloudApproach', Component: WhiskerSection },
  { key: 'openSource', Component: WhiskerSection },
  { key: 'techApproach', Component: WhiskerSection },
  { key: 'cyberResiliency', Component: WhiskerSection },
  // Tech Buying Process
  { key: 'buyingProcess', Component: SummarySection },
  { key: 'budgetProcess', Component: WhiskerSection },
  { key: 'influence', Component: WhiskerSection },
  { key: 'buyingRoles', Component: TextOnlySection },
  { key: 'processSteps', Component: TextOnlySection },
  // Tech Alignment
  { key: 'techStack', Component: TechAlignment },
  // Chatbot
  { key: 'chatbot', Component: ChatbotWrapper },
  // Coaching
  { key: 'coaching', Component: Coaching },
]

export default sectionComponents
