import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  WarningTriangle
} from 'icons'

import ChevronRight from '@mui/icons-material/ChevronRight'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Email from '@mui/icons-material/Email'
import EmojiObjects from '@mui/icons-material/EmojiObjects'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Event from '@mui/icons-material/Event'
import Info from '@mui/icons-material/Info'
import Mic from '@mui/icons-material/Mic'
import PhoneInTalk from '@mui/icons-material/PhoneInTalk'
import Star from '@mui/icons-material/Star'
import SpeakerNotes from '@mui/icons-material/SpeakerNotes'

import {
  customAlert,
  formatDateTime,
  formatDate,
  formatDateTimeRange,
  getEmailRelayLink,
  simpleHttpFetch,
  downloadAssetFromGcs,
} from 'utils-em'

import { useJsonAPIUpsert } from 'hooks'
import {
  AdvisorProfileDialog,
  AdvisorTooltips,
  ConfirmDialog,
  CustomerUserTooltips,
  FeatureAccessControl,
  SanitizedHTML
} from 'components'
import { JsonAPI } from 'store'
import UploadEngagementAssetForm from './components/UploadEngagementAssetForm'
import EngagementCloseDialog from '../../components/EngagementCloseDialog'
import EngagementContractUsageDialog from '../../../../../../components/EngagementContractUsageDialog'
import ZoomLink from './components/ZoomLink'
import EngagementExpertiseRatingsDialog from './components/EngagementExpertiseRatingsDialog'

const styles = {
  sectionTitle: {
    padding: '0.1em 1em',
    backgroundColor: '#01023d',
    color: '#dcdcdc'
  },
  sectionBody: {
    padding: '2em 1em'
  },
  subtitle: {
    color: '#01023d',
    fontSize: '1em',
    margin: '0.25em 1em',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderBottom: '0.2em solid #ebf0ff'
  },
  tableLabel: {
    color: 'black',
    backgroundColor: '#eee',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  timeslot: {
    backgroundColor: '#fefefe',
    borderRadius: '0.33em',
    border: '0.15em solid #ddd',
    padding: '0.25em 0.33em'
  },
  noProposalsMessage: {
    color: '#dcdcdc',
    fontStyle: 'italic',
    marginLeft: '1em'
  },
  pageTitle: {
    padding: '1em',
    color: 'white',
    backgroundColor: '#01023d'
  },
  quoteBlock: {
    borderLeft: '0.33em solid #ebf0ff',
    margin: '1em 0 1em 1em'
  },
  userPill: {
    backgroundColor: '#ddd',
    borderRadius: '0.33em',
    padding: '0.175em 0.33em'
  },
  messageBlock: {
    border: '5px solid #ddd'
  },
  actionButtons: {
    float: 'right'
  },
  headerLink: {
    color: '#FFFFFF',
    textDecoration: 'underline'
  }
}

const EngagementDetail = ({
  engagement,
  engagementId,
  loadEngagementData,
  loadEvents,
  openDialog,
  saveEngagement,
  loadTimeslots
}) => {
  const dispatch = useDispatch()

  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const [loaded, setLoaded] = React.useState(false)
  const [adminNotes, setAdminNotes] = React.useState('')
  const [visibleMessages, setVisibleMessages] = React.useState([])
  const [submittingPayment, setSubmittingPayment] = React.useState(false)
  const [organizationAdvisors, setOrganizationAdvisors] = React.useState([])

  const submitPayment = async () => {
    setSubmittingPayment(true)
    const data = { engagementId: engagement.id }
    await simpleHttpFetch(
      `${__API_HOST__}/v1/tipalti/create`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }
    )
    loadEngagementData(engagementId)
    setSubmittingPayment(false)
  }

  const loadOrganizationAdvisors = async () => {
    const response = await simpleHttpFetch(`${__API_HOST__}/v1/blastableAdvisors?orgId=${engagement.organizationId}`)
    setOrganizationAdvisors(response.data.map((advisor) => advisor))
  }

  const participantEmailList = useMemo(() => {
    const emailList = []
    if (engagement?.participants) {
      engagement.participants.forEach((p) => {
        if (p.user?.email) {
          emailList.push(p.user?.email)
        } else if (p.userEmail) {
          emailList.push(p.userEmail)
        }
      })
    }
    return emailList.join(', ')
  }, [engagement?.participants])

  React.useEffect(
    () => {
      Promise.all([
        loadEngagementData(engagementId),
        loadTimeslots(engagementId),
        loadEvents(engagementId)
      ]).then(() => {
        setLoaded(true)
      })
    },
    []
  )

  React.useEffect(
    () => {
      if (engagement) {
        setAdminNotes(engagement.adminNotes || '')
      }
    },
    [engagement]
  )

  React.useEffect(() => {
    if (engagement?.id) {
      loadOrganizationAdvisors()
    }
  }, [engagement?.id])

  if (!loaded) {
    return null
  }

  const { advisor, assets } = engagement
  const { customer } = engagement.customerUser
  const messages = engagement.messages || []
  const timeslotProposals = engagement.timeslotProposals || []

  const recordingAsset = assets?.find((a) => a.assetType === 'engagement-recording')
  const transcriptAsset = assets?.find((a) => a.assetType === 'engagement-transcript')

  const customerUsers = [engagement.customerUser]

  const timeslotsByStatus = {
    upcoming: [],
    proposed: [],
    completed: [],
    missed: [],
    expired: [],
    rejected: []
  }

  const markCallAsCompleted = (timeslot, proposal) => {
    if (!['active', 'closed'].includes(engagement.state)) {
      openDialog(
        <ConfirmDialog
          description={`Currently, the engagement status is  ${engagement.state}. A call cannot be marked as complete until the engagement status is updated to 'Active'.`}
          actions={[]}
        />
      )
    } else {
      openDialog(
        <ConfirmDialog
          description="Mark call as selected?"
          actions={[
            {
              name: 'save',
              action: async () => {
                dispatch(JsonAPI.save({
                  ...engagement,
                  manuallyCompleteEngagement: true,
                  timeslotIdToSelect: timeslot.id,
                  riders: ['timeslotIdToSelect'],
                }))
                await loadEngagementData(engagementId)
                await loadTimeslots(engagementId)
                customAlert('Call selected.', false)
              }
            }
          ]}
        />
      )
    }
  }

  const emailRelay = getEmailRelayLink(engagement)
  timeslotProposals.forEach((p) => {
    switch (p.status) {
      case 'PROPOSED':
        p.timeslots.forEach((t) => {
          if (moment(t.startTime) > moment()) {
            timeslotsByStatus.proposed.push(t)
          } else {
            timeslotsByStatus.expired.push(t)
          }
        })
        break
      case 'REJECTED':
        timeslotsByStatus.rejected.push(...p.timeslots)
        break
      case 'ACCEPTED': {
        const selectedTimeslot = p.timeslots.filter((t) => t.isSelected)[0]
        if (!selectedTimeslot) { break }
        if (moment(selectedTimeslot.startTime) > moment()) {
          timeslotsByStatus.upcoming.push(selectedTimeslot)
        } else {
          timeslotsByStatus.completed.push(selectedTimeslot)
        }
        break
      }
      case 'RESCHEDULED':
        timeslotsByStatus.rejected.push(...p.timeslots)
        break
      case 'CALL_MISSED':
        timeslotsByStatus.missed.push(...p.timeslots)
        break
      default:
        break
    }
  })

  const renderTimeslot = (timeslot, showDownloadIcons, allowMarkAsCompleted) => {
    const recording = assets?.find((asset) => asset.assetType === 'engagement-recording')
    const transcript = assets && assets?.find((asset) => asset.assetType === 'engagement-transcript')
    const proposal = timeslotProposals.find((tp) => parseInt(tp.id, 10) === timeslot.proposalId)

    /* eslint-disable */
    return (
      <Tooltip title={(
        <>
          <Box>
            Initiating User:
            {' '}
            {proposal.initiatingUser.fullName}
          </Box>
          <Box>
            Responding User:
            {' '}
            {proposal.respondingUser?.fullName}
          </Box>
          {proposal.decliningUser ? (
            <Box>
              Declining User:
              {' '}
              {proposal.decliningUser.fullName}
            </Box>
          ) : null}
        </>
      )}
      >
        <Box sx={{ ...styles.timeslot }}>
          {timeslot.isSelected
            ? (
              <Tooltip title="Selected Timeslot">
                <CheckCircle />
              </Tooltip>
            )
            : null}
          {formatDateTimeRange(timeslot.startTime, timeslot.endTime, { showTimezoneSuffix: true, timezone: timeslot.tzName })}
          {` (${timeslot.tzName})`}
          {
            timeslot.isSelected && showDownloadIcons && recording
              ? (
                <span>
                  <IconButton
                    size="small"
                    onClick={() => downloadAssetFromGcs(recording.id, recording.documentGcsObjectName)}>
                    <Mic />
                  </IconButton>
                </span>
              )
              : null
          }
          {
            timeslot.isSelected && showDownloadIcons && transcript
              ? (
                <span>
                  <IconButton
                    size="small"
                    onClick={() => downloadAssetFromGcs(transcript.id, transcript.documentGcsObjectName)}>
                    <SpeakerNotes />
                  </IconButton>
                </span>
              )
              : null
          }
          {allowMarkAsCompleted && (
            <Box>
              <Button onClick={() => markCallAsCompleted(timeslot, proposal)}>
                Mark as completed
              </Button>
            </Box>
          )}

        </Box>
      </Tooltip>
    )
  }

  const renderAdvisorRow = (advisor) => {
    const advisorAttributes = advisor.attributes
    const advisorId = advisor.id
    const matchingOpportunity = engagement.opportunities.find((o) => o.advisorId === parseInt(advisorId))
    const opportunityStatus = (() => {
      if (!matchingOpportunity) {
        return 'No proposal sent'
      }
      if (matchingOpportunity.status === 'open' || matchingOpportunity.status === 'missed') {
        return 'No response'
      }
      if (matchingOpportunity.status === 'rejected') {
        return 'Declined'
      }
      if (matchingOpportunity.status === 'accepted') {
        return 'Accepted'
      }
      if (matchingOpportunity.status === 'expired') {
        return 'Expired'
      }
      return 'Unknown'
    })()

    return (
      <TableRow key={advisorId}>
        <TableCell>
          {!matchingOpportunity && (<WarningTriangle sx={{ mr: 1, color: 'warning.main' }} />)}
          <Button
            onClick={() => openDialog(
              <AdvisorProfileDialog advisorId={advisorId} />
            )}
          >
            {advisorAttributes.fullName}
          </Button>
          <AdvisorTooltips advisor={advisorAttributes} />
        </TableCell>
        <TableCell>
          <Typography variant="body">
            {advisorAttributes.email}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body">
            {opportunityStatus}
          </Typography>
        </TableCell>
        {!matchingOpportunity && engagement.state === 'awaiting-advisor' && (
          <TableCell>
            <Button
              onClick={() => {
                // open a confirm dialog to confirm if they want to get blasted
                openDialog(
                  <ConfirmDialog
                    title={`Send proposal to ${advisorAttributes.fullName}`}
                    description="This will send a call proposal to the advisor via email, and it will show up on their home page"
                    actions={[
                      {
                        name: 'blast',
                        action: async () => {
                          await upsert({
                            type: 'opportunities',
                            status: 'open',
                            targetObjectId: engagement.id,
                            targetObjectType: 'engagement',
                            advisorId: advisorId,
                            include: 'engagement.opportunities'
                          })
                        }
                      }
                    ]}
                  />
                )

              }}
            >
              Send proposal
            </Button>
          </TableCell>
        )}
      </TableRow>
    )
  }

  const updateEngagement = (newState, closeCommand) => {
    const bodyToSave = { ...engagement, adminNotes }

    saveEngagement(bodyToSave)
  }

  if (!loaded) { return null }

  const orgName = engagement.organization ? engagement.organization.name : 'Unknown'

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card variant="outlined">
            <Grid sx={{ ...styles.pageTitle }} container alignItems="center" spacing={1}>
              <Grid item xs={8}>
                <Typography variant="h5" component="span">
                  <Link
                    sx={{ ...styles.headerLink }}
                    target="_blank"
                    href={`/admin/customers/${customer.id}`}
                  >
                    {customer.name}
                  </Link>
                  {' '}
                  &nbsp;&harr;&nbsp;
                  <>
                    {orgName}
                  </>
                  &nbsp;
                  <span style={{ color: '#bbb' }}>
                    (
                    {advisor?.fullName}
                    )
                  </span>
                </Typography>
              </Grid>
              {engagement.state === 'awaiting-advisor' ? (
                <Grid item xs={4}>
                  <ButtonGroup variant="contained" sx={{ ...styles.actionButtons }}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => openDialog(<EngagementCloseDialog
                        engagementId={engagement.id}
                        closeAction="closeProposal"
                      />)}
                    >
                      Close proposal
                    </Button>
                  </ButtonGroup>
                </Grid>
              ) : null}
              {engagement.state === 'active'
                ? (
                  <Grid item xs={4}>
                    <ButtonGroup variant="contained" sx={{ ...styles.actionButtons }}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => openDialog(<EngagementCloseDialog
                          engagementId={engagement.id}
                          closeAction="cancelEngagement"
                        />)}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => openDialog(<EngagementCloseDialog
                          engagementId={engagement.id}
                          closeAction="refundEngagement"
                        />)}
                      >
                        Refund/Close
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => openDialog(<EngagementCloseDialog
                          engagementId={engagement.id}
                          closeAction="closeEngagementNow"
                        />)}
                      >
                        Close now
                      </Button>
                    </ButtonGroup>
                  </Grid>
                )
                : null}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card variant="outlined">
            <Typography sx={{ ...styles.sectionTitle }} variant="h6">
              <Info style={{ margin: '0 0.25em -0.2em 0em' }} />
              Details
            </Typography>
            <Box sx={{ ...styles.sectionBody }} style={{ margin: '0.5em' }}>
              <TableContainer style={{ border: '2px solid #eee' }}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        State
                      </TableCell>
                      <TableCell>
                        {engagement.state}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Start Date / Length
                      </TableCell>
                      <TableCell>
                        {formatDateTime(engagement.startDate)}
                        {' '}
                        30 days
                      </TableCell>
                    </TableRow>
                    {engagement.dateClosed
                      ? (
                        <TableRow>
                          <TableCell sx={{ ...styles.tableLabel }} variant="head">
                            Close Date
                          </TableCell>
                          <TableCell>
                            {formatDateTime(engagement.dateClosed)}
                          </TableCell>
                        </TableRow>
                      )
                      : null}
                    {engagement.dateCanceled
                      ? (
                        <TableRow>
                          <TableCell sx={{ ...styles.tableLabel }} variant="head">
                            Cancel Date
                          </TableCell>
                          <TableCell>
                            {formatDateTime(engagement.dateCanceled)}
                          </TableCell>
                        </TableRow>
                      )
                      : null}
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Customer
                      </TableCell>
                      <TableCell>
                        {customer.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Organization
                      </TableCell>
                      <TableCell>
                        {engagement.organization ? engagement.organization.name : 'Unknown'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Advisor
                      </TableCell>
                      {advisor ? (
                        <TableCell>
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation()
                              openDialog(
                                <AdvisorProfileDialog
                                  advisorId={parseInt(advisor.id, 10)}
                                />
                              )
                            }}
                          >
                            {advisor.fullName}
                          </button>
                          <AdvisorTooltips advisor={advisor} />
                        </TableCell>
                      ) : (
                        <TableCell>
                          <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ ...styles.userPill }}>
                              {organizationAdvisors.length}
                              &nbsp;Eligible Advisors
                            </Typography>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Customer Users
                      </TableCell>
                      <TableCell>
                        <Grid container spacing={1}>
                          {customerUsers.map(
                            (cu, idx) => (
                              <Grid item key={cu.id}>
                                <Typography sx={{ ...styles.userPill }}>
                                  {idx === 0 ? (<Star style={{ height: '0.5em' }} />) : null}
                                  {cu.fullName}
                                </Typography>
                                <CustomerUserTooltips customerUser={cu} />
                              </Grid>
                            )
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Other Relay Emails
                      </TableCell>
                      <TableCell>
                        {participantEmailList}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Relay
                      </TableCell>
                      <TableCell>
                        <Link href={`mailto:${emailRelay}`}>
                          {emailRelay}
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Zoom Meeting ID
                      </TableCell>
                      <TableCell>
                        <ZoomLink engagementId={engagementId} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Advisor Rate
                      </TableCell>
                      <TableCell>
                        {engagement.fee}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Notes
                      </TableCell>
                      <TableCell>
                        {engagement.summaryUrl
                          ? (
                            <Button
                              href={engagement.summaryUrl}
                              target="_blank"
                              startIcon={<EmojiObjects />}
                              disabled={!engagement.summaryUrl}
                            >
                              Notes
                            </Button>
                          )
                          : 'Not available'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Advisor set self available:
                      </TableCell>
                      <TableCell>
                        {formatDate(engagement.dateAdvisorIndicatedAvailable)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Charges
                      </TableCell>
                      <TableCell>
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault()
                            openDialog(<EngagementContractUsageDialog
                              charges={engagement.charges}
                            />)
                          }}
                        >
                          {`${engagement.charges.reduce((acc, cs) => acc + cs.unitCount, 0)} credit(s)`}
                        </button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Recording
                      </TableCell>
                      <TableCell>
                        <UploadEngagementAssetForm
                          engagementId={engagementId}
                          assetType="engagement-recording"
                          initialAsset={recordingAsset}
                          onUploadComplete={() => loadEngagementData(engagementId)}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Transcript
                      </TableCell>
                      <TableCell>
                        <UploadEngagementAssetForm
                          engagementId={engagementId}
                          assetType="engagement-transcript"
                          initialAsset={transcriptAsset}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...styles.tableLabel }} variant="head">
                        Areas of Expertise Ratings
                      </TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => openDialog(<EngagementExpertiseRatingsDialog engagementId={engagementId} />)}
                          disabled={!transcriptAsset}
                          sx={{ mb: 1 }}
                        >
                          View Areas of Expertise
                        </Button>
                        {!transcriptAsset && (
                          <Box typography="tiny">
                            A pdf transcript is required to view/generate areas of expertise ratings from this call
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                    <FeatureAccessControl feature="tipalti_integration">
                      <TableRow>
                        <TableCell sx={{ ...styles.tableLabel }} variant="head">
                          Payment
                        </TableCell>
                        <TableCell>
                          {
                            (!engagement.payment ||
                              engagement.payment.tipaltiSubmissionStatus === 'failed') ? (
                              <>
                                <Button
                                  disabled={submittingPayment || timeslotsByStatus.completed.length === 0}
                                  variant="outlined"
                                  size="small"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    submitPayment()
                                  }}
                                >
                                  {!engagement.payment ? 'Submit' : 'Payment failed. Resubmit'} Tipalti Payment
                                </Button>
                                {timeslotsByStatus.completed.length === 0 ? (
                                  <small>
                                    &nbsp;(No completed calls yet)
                                  </small>
                                ) : null}
                              </>
                            ) : (
                              engagement.payment.paymentStatus === 'PAID' ? 'Completed' : 'Pending'
                            )
                          }
                        </TableCell>
                      </TableRow>
                    </FeatureAccessControl>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Typography sx={{ ...styles.sectionTitle }} variant="h6">
              {`${engagement.organization.name} Advisors:`}
            </Typography>
            <Box sx={{ ...styles.sectionBody }}>
              <Table>
                <TableBody>
                  {organizationAdvisors.map((advisor) => renderAdvisorRow(advisor))}
                </TableBody>
              </Table>
            </Box>
            { engagement.agenda && (
              <>
                <Typography sx={{ ...styles.sectionTitle }} variant="h6">
                  Agenda
                </Typography>
                <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}> {engagement.agenda} </Typography>
              </>
            )}
            <Typography sx={{ ...styles.sectionTitle }} variant="h6">
              <Email style={{ margin: '0 0.25em -0.2em 0em' }} />
              Email Relay
            </Typography>
            <Box sx={{ ...styles.sectionBody }}>
              {messages.length === 0 ? (
                <i>No relay messages</i>
              ) : messages.map((m) => (
                <Box key={m.id} sx={{ ...styles.quoteBlock }}>
                  <Typography sx={{ ...styles.subtitle }} variant="subtitle1">
                    <IconButton
                      onClick={() => (visibleMessages.includes(m.id) ? setVisibleMessages(visibleMessages.filter((msgId) => msgId !== m.id)) : setVisibleMessages([...visibleMessages, m.id]))}
                      size="large"
                    >
                      {visibleMessages.includes(m.id) ? <ExpandMore /> : <ChevronRight />}
                    </IconButton>
                    Subject: &ldquo;
                    {m.subject}
                    &rdquo;
                    {' '}
                    (
                    {formatDateTime(m.dateCreated)}
                    )
                  </Typography>
                  {visibleMessages.includes(m.id)
                    ? (
                      <Box style={{ padding: '1em' }}>
                        {m.textBody !== '' ? m.textBody : <SanitizedHTML html={m.htmlBody} />}
                      </Box>
                    )
                    : null}
                </Box>
              ))}
            </Box>
          </Card>
        </Grid>
        <Grid container item direction="column" spacing={1} xs={4}>
          <Grid container item spacing={1}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <Typography sx={{ ...styles.sectionTitle }} variant="h6">
                  Admin Notes
                </Typography>
                <Divider />
                <Typography variant="subtitle1" align="left">
                  <TextField
                    multiline
                    rows={5}
                    label="Add/Edit Notes"
                    value={adminNotes}
                    onChange={(e) => { setAdminNotes(e.currentTarget.value) }}
                    margin="dense"
                    style={{ margin: '1em', width: '90%' }}
                  />
                </Typography>
                <Button color="primary" variant="contained" onClick={updateEngagement} style={{ margin: '1em' }}>
                  Save
                </Button>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card variant="outlined">
                <Typography sx={{ ...styles.sectionTitle }} variant="h6">
                  <PhoneInTalk style={{ margin: '0 0.25em -0.2em 0em' }} />
                  Calls
                </Typography>
                <Box sx={{ ...styles.sectionBody }}>
                  <Typography sx={{ ...styles.subtitle }} variant="subtitle1">Upcoming</Typography>
                  {timeslotsByStatus.upcoming.length === 0 ? (<Typography sx={{ ...styles.noProposalsMessage }} variant="body1">None</Typography>) : null}
                  <List dense>
                    {timeslotsByStatus.upcoming.map((t) => (
                      <ListItem key={t.id}>
                        {renderTimeslot(t)}
                      </ListItem>
                    ))}
                  </List>
                  <Typography sx={{ ...styles.subtitle }} variant="subtitle1">Completed</Typography>
                  {timeslotsByStatus.completed.length === 0 ? (<Typography sx={{ ...styles.noProposalsMessage }} variant="body1">None</Typography>) : null}
                  <List dense>
                    {timeslotsByStatus.completed.map((t) => (
                      <ListItem key={t.id}>
                        {renderTimeslot(t, true)}
                      </ListItem>
                    ))}
                  </List>
                  <Typography sx={{ ...styles.subtitle }} variant="subtitle1">Proposed</Typography>
                  {timeslotsByStatus.proposed.length === 0 ? (<Typography sx={{ ...styles.noProposalsMessage }} variant="body1">None</Typography>) : null}
                  <List dense>
                    {timeslotsByStatus.proposed.map((t) => (
                      <ListItem key={t.id}>
                        {renderTimeslot(t)}
                      </ListItem>
                    ))}
                  </List>
                  <Typography sx={{ ...styles.subtitle }} variant="subtitle1">Missed</Typography>
                  {timeslotsByStatus.missed.length === 0 ? (<Typography sx={{ ...styles.noProposalsMessage }} variant="body1">None</Typography>) : null}
                  <List dense>
                    {timeslotsByStatus.missed.map((t) => (
                      <ListItem key={t.id}>
                        {renderTimeslot(t, timeslotsByStatus.completed.length === 0, true)}
                      </ListItem>
                    ))}
                  </List>
                  <Typography sx={{ ...styles.subtitle }} variant="subtitle1">Rejected</Typography>
                  {timeslotsByStatus.rejected.length === 0 ? (<Typography sx={{ ...styles.noProposalsMessage }} variant="body1">None</Typography>) : null}
                  <List dense>
                    {timeslotsByStatus.rejected.map((t) => (
                      <ListItem key={t.id}>
                        {renderTimeslot(t, false, true)}
                      </ListItem>
                    ))}
                  </List>
                  <Typography sx={{ ...styles.subtitle }} variant="subtitle1">Expired</Typography>
                  {timeslotsByStatus.expired.length === 0 ? (<Typography sx={{ ...styles.noProposalsMessage }} variant="body1">None</Typography>) : null}
                  <List dense>
                    {timeslotsByStatus.expired.map((t) => (
                      <ListItem key={t.id}>
                        {renderTimeslot(t, false, true)}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card variant="outlined">
                <Typography sx={{ ...styles.sectionTitle }} variant="h6">
                  <Event style={{ margin: '0 0.25em -0.2em 0em' }} />
                  Events
                </Typography>
                <Table>
                  {engagement.events?.map((event) => (
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">
                          {event.eventName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {formatDateTime(event.dateCreated)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

EngagementDetail.propTypes = {
  engagement: PropTypes.object,
  engagementId: PropTypes.number.isRequired,
  loadEngagementData: PropTypes.func.isRequired,
  saveEngagement: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  loadTimeslots: PropTypes.func.isRequired,
  loadEvents: PropTypes.func.isRequired
}

EngagementDetail.defaultProps = {
  engagement: null
}

export default EngagementDetail
