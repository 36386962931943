import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Divider,
  Typography
} from '@mui/material'

import {
  useEmissaryTheme,
} from 'hooks'
import {
  ArrowRight
} from 'icons'
import useAccountPageContext from './hooks/useAccountPageContext'
import SideNav from './components/SideNav'
import Header from './components/Header'
import StickyBar from './components/StickyBar'

const AccountPageUpdated = ({ accountPageId }) => {
  const [stickyBarVisible, setStickyBarVisible] = React.useState(false)
  const { contentHeight } = useEmissaryTheme()
  const headerRef = React.useRef()
  const {
    activeSection,
    ActiveComponent,
    nextVisibleSection,
    handleActiveSectionChange,
  } = useAccountPageContext(accountPageId)
  const nextButtonVisible = Boolean(nextVisibleSection) && !activeSection?.summary

  React.useEffect(() => {
    const handleScroll = () => {
      const { offsetHeight, offsetTop } = headerRef?.current || {}
      if (!offsetHeight || !offsetTop) return
      setStickyBarVisible(window.scrollY > offsetHeight + offsetTop)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const NextButton = () => (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <Button
        variant="outlined"
        startIcon={<ArrowRight />}
        onClick={() => {
          window.scrollTo(0, 0)
          handleActiveSectionChange(nextVisibleSection.key)
        }}
      >
        Next topic
      </Button>
      <Typography variant="tiny">{nextVisibleSection.title}</Typography>
    </Box>
  )

  return (
    <Box
      id="account-page-updated"
      sx={{
        minHeight: contentHeight,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <StickyBar isOpen={stickyBarVisible} />
      <Box ref={headerRef}>
        <Header accountPageId={accountPageId} />
      </Box>
      <Box sx={{ flex: 1, display: 'flex', gap: 3 }}>
        <Box sx={{
          width: '360px',
          p: 1,
          position: 'relative',
          borderRightWidth: '1px',
          borderRightColor: 'neutral.lightGrey',
          borderRightStyle: 'solid'
        }}
        >
          <SideNav />
        </Box>
        <Box sx={{ flex: 1 }}>
          {ActiveComponent && <ActiveComponent />}
          {nextButtonVisible && <Divider sx={{ my: 5, borderBottomWidth: '1px' }} />}
          {nextButtonVisible && <NextButton />}
        </Box>
      </Box>
      <Box>
        <Divider sx={{ my: 5, borderBottomWidth: '1px' }} />
        <Typography variant="caption" color="neutral.black" sx={{ mt: 3, display: 'block' }}>
          {`This report is licensed to you under the Emissary Inc. customer agreement for your organization's
              internal use only - you may not redistribute, sell, license or otherwise transfer it without
              Emissary's prior written approval. Although the information in this report is believed to have been
              obtained from reliable sources, much of this information is subjective in nature and in all cases,
              Emissary makes no representation as to its accuracy or completeness.`}
        </Typography>
      </Box>
    </Box>
  )
}

AccountPageUpdated.propTypes = {
  accountPageId: PropTypes.string.isRequired
}

export default AccountPageUpdated
