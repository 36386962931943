/* eslint-disable react/prop-types */
import React from 'react'
import {
  Box,
} from '@mui/material'
import {
  AccountSavedCard
} from 'components'
import useAccountPageContext from '../hooks/useAccountPageContext'

const SideNav = () => {
  const { visibleSections, savedAccountState, activeSectionKey, handleActiveSectionChange } = useAccountPageContext()

  const SectionLink = ({ section }) => {
    const isActive = activeSectionKey === section.key
    const isChild = section.parentKey
    const typography = (() => {
      if (isChild) return isActive ? 'captionBold' : 'caption'
      return isActive ? 'bodyBold' : 'body1'
    })()

    return (
      <Box
        onClick={() => handleActiveSectionChange(section.key)}
        sx={{
          px: 2,
          py: 1,
          pl: isChild ? 4 : 2,
          typography,
          cursor: 'pointer',
          bgcolor: isActive ? 'primary.lightest' : '',
          ':hover': { bgcolor: 'primary.lightest' }
        }}
      >
        {section.title}
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
      {visibleSections.map((section) => (
        <SectionLink key={section.key} section={section} />
      ))}
      <AccountSavedCard accountSaved={savedAccountState?.accountSaved} />
    </Box>
  )
}

export default SideNav
