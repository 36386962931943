import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import {
  useAccountSaved,
  useFeatureFlag,
  useJsonAPIGetAll,
  useJsonAPIGetOne,
  useSessionUser
} from 'hooks'
import {
  accountPageOrgHasChatbot,
} from 'utils-em'
import sections from '../fixtures/sections'
import routes from '../routes'

export const AccountPageContext = React.createContext({})

export const AccountPageContextProvider = ({ accountPageId, children }) => {
  // load account page here
  const { object: accountPage, loaded } = useJsonAPIGetOne(
    accountPageId,
    'accountPages',
    ['organization', 'organization.tags'],
    {
      'lazy[organizations]': 'engageableAdvisorCount,totalEngageableAdvisorCount',
      'lazy[accountPages]': ['contributingAdvisorSummary', 'surveyResults', 'mostRecentUpdateDate', 'logo'].join(',')
    }
  )

  const { objects: questionResponses, loaded: responsesLoaded } = useJsonAPIGetAll('questionResponses', {
    skip: !loaded,
    queryStringParams: {
      include: 'question,question.topics,advisor,advisor.companies,advisor.companies.roles,advisor.companies.roles.tags',
      filter: [
        { name: 'organizationId', op: 'eq', val: accountPage?.organizationId },
        { name: 'isDirectToPage', op: 'eq', val: 1 },
      ]
    },
  })

  const { isUnlimitedByAccount } = useSessionUser()
  const chatbotEnabled = useFeatureFlag('chatbot_beta')
  const { hash } = useLocation()
  const [visibleSections, setVisibleSections] = React.useState([])
  const [nextVisibleSection, setNextVisibleSection] = React.useState(null)
  const savedAccountState = useAccountSaved(accountPage?.organization?.id)
  const [activeSectionKey, setActiveSectionKey] = React.useState(hash?.slice(1))
  const [isChatbotVisible, setIsChatbotVisible] = React.useState(false) // has data and enabled
  const engageableAdvisorCount = accountPage?.organization?.engageableAdvisorCount || 0
  const isCoachingVisible = !isUnlimitedByAccount && engageableAdvisorCount > 0

  const handleActiveSectionChange = (sectionKey) => {
    setActiveSectionKey(sectionKey)
    window.location.href = `#${sectionKey}` // using history.push causes the whole page to reload so use window instead
  }

  // update landing page section to next section if not visible
  React.useEffect(() => {
    if (hash || !visibleSections) return
    const visibleSection = visibleSections.find((section) => section.key === activeSectionKey)
    if (!visibleSection) setActiveSectionKey(visibleSections[0]?.key)
  }, [activeSectionKey, visibleSections])

  // update next visible section on change
  React.useEffect(() => {
    if (!visibleSections) return
    const currentIndex = visibleSections.findIndex((section) => section.key === activeSectionKey)
    if (currentIndex === -1) return
    setNextVisibleSection(visibleSections?.[currentIndex + 1])
  }, [activeSectionKey])

  // Because we are not using history, we need to update state when the hash changes when using the back/forward buttons
  React.useEffect(() => {
    const handleHashChange = () => {
      if (!visibleSections?.length) return
      const newHash = window.location.hash.slice(1)
      setActiveSectionKey(newHash || visibleSections[0]?.key)
    }
    window.addEventListener('popstate', handleHashChange)
    return () => { window.removeEventListener('popstate', handleHashChange) }
  }, [])

  // Compute if chatbot is visible
  React.useEffect(() => {
    if (!accountPage?.organizationId) return
    accountPageOrgHasChatbot(accountPage.organizationId).then((res) => {
      setIsChatbotVisible(res && chatbotEnabled)
    })
  }, [accountPage?.organizationId])

  // Set visible sections and next visible section after load
  React.useEffect(() => {
    const getVisibleSections = async () => {
      if (!accountPage || !responsesLoaded) return []
      return [
        ...sections.filter((section) => getIsSectionVisible(section.key)),
        ...(!isChatbotVisible ? [] : [
          { key: 'chatbot', title: 'Chatbot' }
        ]),
        ...(!isCoachingVisible ? [] : [
          { key: 'coaching', title: `${accountPage?.organization?.name} Advisors` }
        ]),
      ]
    }
    if (!accountPage) return
    getVisibleSections(accountPage).then((res) => { setVisibleSections(res) })
  }, [accountPage?.id, isChatbotVisible, isCoachingVisible, responsesLoaded])

  const getSection = (key) => visibleSections?.find((section) => section.key === key)

  const getSectionData = (key) => {
    // gets the account page data for a given key
    if (!accountPage?.data) return null
    const { data } = accountPage

    // First level search
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      return data[key]
    }

    // Second level search if the value is an object
    const parentKey = Object.keys(data).find((k) => (
      typeof data[k] === 'object' && !Array.isArray(data[k]) &&
      Object.prototype.hasOwnProperty.call(data[k] || {}, key)
    ))
    return data[parentKey]?.[key] || null
  }

  const getSectionResponses = (key) => {
    // i hate this section
    if (key === 'artificialIntelligence') {
      const levelOfAdoptionResponses = getSectionResponses('levelOfAdoption')
      const impedingAIAdoptionResponses = getSectionResponses('impedingAIAdoption')
      const aiUseCasesResponses = getSectionResponses('aiUseCases')
      return [
        ...levelOfAdoptionResponses,
        ...impedingAIAdoptionResponses,
        ...aiUseCasesResponses
      ]
    }

    const section = sections.find((sec) => sec.key === key)
    const topicKey = section?.topicKey || key

    const responses = questionResponses
      ?.filter((response) => response.question?.topics?.map((topic) => topic.key)?.includes(topicKey))
      .sort((a, b) => a.directToPageSort - b.directToPageSort)

    // why god why
    if (key === 'levelOfAdoption') {
      const quantResponses = questionResponses
        ?.filter((response) => response.question?.topics?.map((topic) => topic.key)?.includes('levelOfAdoptionQuant'))
      return [
        ...responses,
        ...quantResponses
      ]
    }

    return responses
  }

  const getSectionSurveyResults = (key) => accountPage?.surveyResults?.results?.[key] || null

  const getIsSectionVisible = (key) => {
    const getHasData = (data) => {
      // return false if data is null, an empty array, or an empty object
      if (!data) return false
      if (Array.isArray(data) && data.length === 0) return false
      if (typeof data === 'object' && Object.keys(data).length === 0) return false
      return true
    }

    // i hate this section
    if (key === 'artificialIntelligence') {
      const levelOfAdoptionResponses = getSectionResponses('levelOfAdoption')
      const impedingAIAdoptionResponses = getSectionResponses('impedingAIAdoption')
      const aiUseCasesResponses = getSectionResponses('aiUseCases')
      const levelOfAdoptionSurveyResults = getSectionSurveyResults('levelOfAdoption')
      const impedingAIAdoptionSurveyResults = getSectionSurveyResults('impedingAIAdoption')
      const aiUseCasesSurveyResults = getSectionSurveyResults('aiUseCases')
      return (
        getHasData(levelOfAdoptionResponses) || getHasData(impedingAIAdoptionResponses) || getHasData(aiUseCasesResponses) ||
        getHasData(levelOfAdoptionSurveyResults) || getHasData(impedingAIAdoptionSurveyResults) || getHasData(aiUseCasesSurveyResults)
      )
    }

    const sectionData = getSectionData(key)
    const sectionResponses = getSectionResponses(key)
    const sectionSurveyResults = getSectionSurveyResults(key)

    return getHasData(sectionData) || getHasData(sectionResponses) || getHasData(sectionSurveyResults)
  }

  const activeSection = visibleSections?.find((section) => section.key === activeSectionKey)
  const activeSectionTitle = activeSection?.title
  const activeSectionQuestionText = activeSection?.questionText
  const activeSectionData = getSectionData(activeSectionKey)
  const activeSectionResponses = getSectionResponses(activeSectionKey)
  const activeSurveyResults = getSectionSurveyResults(activeSectionKey)
  const activeSectionTopicKey = activeSection?.topicKey || activeSectionKey
  const isWeJustHeardVisible = Boolean(accountPage?.data?.weJustHeard?.length > 0)
  const hasCompletedSurveyResults = Boolean(accountPage?.surveyResults?.isComplete)
  const ActiveComponent = routes.find((route) => route.key === activeSectionKey)?.Component

  const contextValue = React.useMemo(() => ({
    ...accountPage,
    loaded,
    ActiveComponent,
    activeSection,
    activeSectionData,
    activeSectionKey,
    activeSectionTitle,
    activeSectionResponses,
    activeSectionQuestionText,
    activeSectionTopicKey,
    activeSurveyResults,
    visibleSections,
    nextVisibleSection,
    questionResponses,
    savedAccountState, // result of useAccountSaved
    isChatbotVisible, // has data and enabled
    isWeJustHeardVisible,
    hasCompletedSurveyResults, // has completed surveys

    // functions
    handleActiveSectionChange,
    getSection,
    getSectionData,
    getSectionResponses,
    getSectionSurveyResults,
    getIsSectionVisible
  }), [
    accountPage,
    activeSectionData,
    activeSectionKey,
    visibleSections,
    nextVisibleSection,
    savedAccountState
  ])

  return (
    <AccountPageContext.Provider value={contextValue}>
      {children}
    </AccountPageContext.Provider>
  )
}

AccountPageContextProvider.propTypes = {
  accountPageId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}
