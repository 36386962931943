/* eslint-disable react/prop-types */
import React from 'react'

import {
  Box,
  Button,
  Divider,
  Typography
} from '@mui/material'

import {
  Bullet
} from 'icons'
import useAccountPageContext from '../hooks/useAccountPageContext'
import AdvisorQuoteBox from '../components/AdvisorQuoteBox'
import SectionHeader from '../components/SectionHeader'

const SummarySection = () => {
  const {
    visibleSections,
    activeSectionKey,
    getSectionResponses,
    getSection,
    getSectionData,
    handleActiveSectionChange,
  } = useAccountPageContext()

  // get the order of the subsections and their keys and data
  const summarySections = visibleSections
    ?.filter((section) => section.parentKey === activeSectionKey)
    .map((section) => ({
      sectionKey: section.key,
      ...section,
      data: getSectionData(section.key),
      responses: getSectionResponses(section.key)?.filter((response) => response.answerType === 'text')
    }))

  const Section = ({ sectionKey, title, data, responses }) => {
    const section = getSection(sectionKey)
    const quote = responses?.[0]?.displayTextAnswer

    // section.paragraph is for buyingRoles processSteps
    const sectionTitle = data?.title || section?.paragraph

    const ColumnBox = ({ children, ...rest }) => (
      <Box display="flex" flexDirection="column" gap={3} {...rest}>
        {children}
      </Box>
    )

    const BulletedSectionTitle = () => (
      <Box sx={{ display: 'flex', columnGap: 1 }}>
        <Bullet />
        <Typography variant="body1">{sectionTitle}</Typography>
      </Box>
    )

    return (
      <ColumnBox>
        <ColumnBox sx={{ pl: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5">{title}</Typography>
            <Button onClick={() => handleActiveSectionChange(sectionKey)}>
              Go to section
            </Button>
          </Box>
          {sectionTitle && <BulletedSectionTitle />}
        </ColumnBox>
        {quote && <AdvisorQuoteBox quote={quote} />}
      </ColumnBox>
    )
  }

  return (
    <>
      <Box sx={{ mb: 5, pl: 3 }}>
        <SectionHeader />
      </Box>
      {summarySections.map((section, index) => (
        <Box key={section.sectionKey}>
          <Section {...section} />
          {index < summarySections.length - 1 && <Divider sx={{ mt: 3, mb: 5, borderBottomWidth: '1px' }} />}
        </Box>
      ))}
    </>
  )
}

export default SummarySection
