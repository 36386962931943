import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Divider,
  Typography
} from '@mui/material'

import { useSessionUser } from 'hooks'
import {
  ArrowLeft,
  Clipboard
} from 'icons'
import {
  BookmarkAccountButton,
} from 'components'
import {
  copyToClipboardWithAlert,
  navigateTo
} from 'utils-em'
import useAccountPageContext from '../hooks/useAccountPageContext'
import Logo from './Logo'
import BookACallButton from './BookACallButton'

const Header = () => {
  const { organization, savedAccountState } = useAccountPageContext()
  const { isAdmin } = useSessionUser()

  return (
    <>
      <Box sx={{ display: 'flex', px: 2, py: 1.5 }}>
        <Button
          variant="text"
          startIcon={<ArrowLeft />}
          disabled={isAdmin}
          onClick={() => navigateTo('/c')}
        >
          Back
        </Button>
        <Box sx={{ ml: 'auto', display: 'flex' }}>
          {!isAdmin && organization?.id && (
            <BookmarkAccountButton orgId={organization.id} {...savedAccountState} />
          )}
          <Button
            variant="text"
            startIcon={<Clipboard />}
            disabled={isAdmin}
            onClick={() => copyToClipboardWithAlert(
              window.location.href,
              'Link copied to clipboard.'
            )}
          >
            Copy link
          </Button>
        </Box>
      </Box>
      <Box sx={{ px: 3, py: 1.5 }}>
        <Logo />
      </Box>
      {organization?.id && (
        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', ml: 3, mb: 3 }}>
          <BookACallButton organizationId={organization.id} />
          {isAdmin && (
            <Typography variant="caption" color="error">
              Admin preview: some features are disabled and/or may not function correctly.
            </Typography>
          )}
        </Box>
      )}
      <Divider sx={{ mt: 5, mb: 5, borderColor: 'brand.paleSlate', borderBottomWidth: '4px' }} />
    </>

  )
}

Header.propTypes = {
  accountPageId: PropTypes.string.isRequired,
  savedAccountState: PropTypes.object.isRequired
}

export default Header
