/* eslint-disable react/prop-types */
import React from 'react'
import {
  Box,
  Divider,
  Typography,
} from '@mui/material'

import useAccountPageContext from '../hooks/useAccountPageContext'
import SectionHeader from '../components/SectionHeader'
import AdvisorResponses from '../components/AdvisorResponses'
import WhiskerCard from '../components/WhiskerCard'
import { getScoredLabel } from '../fixtures/labels'

const AI_SECTIONS = [
  {
    sectionKey: 'levelOfAdoption',
    quantKey: 'levelOfAdoptionQuant',
    questionText: 'How would you rate your former organization\'s level of AI adoption?'
  },
  {
    sectionKey: 'impedingAIAdoption',
    questionText: 'Describe what is impeding progress towards AI adoption.'
  },
  {
    sectionKey: 'aiUseCases',
    questionText: 'Please list 3 use cases where AI is being piloted or utilized within your former employer.'
  },
]

const ArtificialIntelligence = () => {
  const {
    activeSectionKey,
    getSectionResponses,
    getSectionSurveyResults,
    organization
  } = useAccountPageContext()

  const sections = AI_SECTIONS.map((section) => ({
    ...section,
    surveyResults: getSectionSurveyResults(section.quantKey),
    responses: getSectionResponses(section.sectionKey)
  }))

  const visibleSections = sections.filter(({ responses, surveyResults }) => responses?.length > 0 || surveyResults?.results?.length > 0)

  const ColumnBox = ({ children, ...rest }) => (
    <Box display="flex" flexDirection="column" gap={3} {...rest}>
      {children}
    </Box>
  )

  const Section = ({ sectionKey, questionText, responses, surveyResults }) => {
    const { min, avg, max } = surveyResults || {}
    const {
      titleLow,
      titleHigh,
      sentence
    } = getScoredLabel(activeSectionKey, organization?.name, avg) || {}

    return (
      <ColumnBox>
        <ColumnBox sx={{ pl: 3, gap: 1 }}>
          <Typography variant="tiny">We asked advisors:</Typography>
          <Typography variant="h5">{questionText}</Typography>
        </ColumnBox>
        {surveyResults && (
          <WhiskerCard
            chartLow={min}
            chartMid={avg}
            chartHigh={max}
            textMin={titleLow}
            textMax={titleHigh}
            title={sentence}
          />
        )}
        {responses && (
          <Box sx={{ pl: 3 }}>
            <AdvisorResponses keyOverride={sectionKey} />
          </Box>
        )}
      </ColumnBox>
    )
  }

  return (
    <ColumnBox>
      <Box sx={{ pl: 3 }}>
        <SectionHeader />
      </Box>
      {visibleSections.map((section, index) => (
        <ColumnBox>
          <Section key={section.sectionKey} {...section} />
          {index < visibleSections.length - 1 && <Divider sx={{ mt: 0, mb: 3, borderBottomWidth: '1px' }} />}
        </ColumnBox>
      ))}
    </ColumnBox>
  )
}

export default ArtificialIntelligence
