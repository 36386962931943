const sections = [
  // Account Selling Tips
  {
    key: 'sellingTips',
    title: 'Account Selling Tips',
    topicKey: 'sellerAdvice',
    questionText: 'What is the best tip you could give someone who is trying to sell a technology solution, specifically into your former organization?'
  },

  // Tech Spend Drivers
  {
    key: 'propensityToBuy',
    title: 'Tech Spend Drivers',
    summary: true
  },
  {
    key: 'techBudget',
    parentKey: 'propensityToBuy',
    title: 'Tech Budget Trends',
    questionText: 'How would you describe your former organization\'s budget for technology investments?',
  },
  {
    key: 'riskTolerance',
    parentKey: 'propensityToBuy',
    title: 'Risk Tolerance',
    questionText: 'How would you describe the risk tolerance at your former organization when it comes to making technology investments?',
  },
  {
    key: 'makeOrBuy',
    parentKey: 'propensityToBuy',
    title: 'Build vs Buy',
    questionText: 'How would you describe your former organization\'s make vs. buy preference when it comes to technology solutions?',
  },
  {
    key: 'artificialIntelligence',
    parentKey: 'propensityToBuy',
    title: 'Artificial Intelligence'
  },
  {
    key: 'digitalTransformation',
    parentKey: 'propensityToBuy',
    title: 'Digital Transformation',
    questionText: 'How mature is your former organization\'s approach to digital transformation?',
  },

  // Tech Approach
  {
    key: 'techPreferences',
    title: 'Technology Approach',
    summary: true
  },
  {
    key: 'techStrategy',
    parentKey: 'techPreferences',
    title: 'Tech Strategy Ownership',
    questionText: 'How is the technology strategy developed and managed, siloed in the businesses or via central corporate IT?',
  },
  {
    key: 'cloudApproach',
    parentKey: 'techPreferences',
    title: 'Cloud Migration',
    questionText: 'How would you describe your former organization\'s approach to cloud migration?',
  },
  {
    key: 'openSource',
    parentKey: 'techPreferences',
    title: 'Perspective on Open-Source',
    questionText: 'What is your former organization\'s perspective on open-source solutions?',
  },
  {
    key: 'techApproach',
    parentKey: 'techPreferences',
    title: 'Broad vs Narrow Solutions',
    questionText: 'Does your former organization prefer broad/all-encompassing technology or lean towards assembling best-of-breed/point solutions?',
  },
  {
    key: 'cyberResiliency',
    parentKey: 'techPreferences',
    title: 'Cyber Resiliency',
    questionText: 'How mature is your former organization\'s approach to cyber resiliency?',
  },

  // Tech Buying Process
  {
    key: 'buyingProcess',
    title: 'Tech Buying Process',
    summary: true
  },
  {
    key: 'budgetProcess',
    parentKey: 'buyingProcess',
    title: 'Budgeting Process',
    questionText: 'How flexible is the budgeting process when it comes to technology purchases (e.g. ability to fund projects out of cycle)?',
  },
  {
    key: 'influence',
    parentKey: 'buyingProcess',
    title: 'Procurement\'s Influence',
    questionText: 'How much influence does procurement have in technology purchases?',
  },
  {
    key: 'buyingRoles',
    parentKey: 'buyingProcess',
    title: 'Tech Buying Roles',
    paragraph: 'Sell to these decision-making roles and influential teams.',
  },
  {
    key: 'processSteps',
    parentKey: 'buyingProcess',
    title: 'Buying Process Steps',
    paragraph: 'Align your sales process to these steps in this account’s buying process.'
  },

  // Tech Alignment
  {
    key: 'techStack',
    title: 'Tech Alignment'
  },
]

export default sections
