/* eslint-disable react/prop-types */
import React from 'react'
import {
  Box,
  Typography
} from '@mui/material'

import {
  Information
} from 'icons'

import useAccountPageContext from '../hooks/useAccountPageContext'
import BulletedParagraph from '../components/BulletedParagraph'
import SectionHeader from '../components/SectionHeader'

const TextOnlySection = () => {
  const { activeSectionData } = useAccountPageContext()

  const InfoText = () => (
    <Box
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Information sx={{ height: '16px', width: '16px', color: 'neutral.darkGrey' }} />
      <Typography variant="tiny" color="neutral.darkGrey" sx={{ ml: 0.5 }}>
        This was written by a writer based on our advisors’ responses
      </Typography>
    </Box>
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, pl: 3 }}>
      <SectionHeader />
      <BulletedParagraph paragraph={activeSectionData?.text} />
      <InfoText />
    </Box>

  )
}

export default TextOnlySection
