/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Button,
  Chip,
  Typography
} from '@mui/material'

import {
  Quotation
} from 'icons'
import {
  formatDate
} from 'utils-em'
import useAccountPageContext from '../hooks/useAccountPageContext'
import { getScoredLabel } from '../fixtures/labels'

const INITIAL_DISPLAYED_RESPONSES = 5

const AdvisorResponses = ({ keyOverride }) => {
  const { activeSectionKey, activeSectionTopicKey, organization, getSectionResponses } = useAccountPageContext()
  const key = keyOverride || activeSectionKey
  const activeSectionResponses = getSectionResponses(key)
  const [showAll, setShowAll] = React.useState(false)
  const textResponses = activeSectionResponses.filter((response) => response.answerType === 'text')
  const quantResponses = activeSectionResponses.filter((response) => response.answerType === 'number')
  const displayedResponses = showAll ? textResponses : textResponses.slice(0, INITIAL_DISPLAYED_RESPONSES)

  const getRelevantTags = (advisor) => {
    // get unique advisor tags from this org, function tags first, then region
    if (!advisor || !organization) return []
    const relevantCompany = advisor?.companies.find((company) => company.organizationId === parseInt(organization?.id, 10))
    const allTags = relevantCompany?.roles?.map((role) => role.tags)?.flat() || []
    const functionTagIds = allTags.filter((tag) => tag.category === 'function-of-role').map((tag) => tag.id)
    const regionTagIds = allTags.filter((tag) => tag.category === 'region').map((tag) => tag.id)
    const relevantTagIds = Array.from(new Set([...functionTagIds, ...regionTagIds]))
    const uniqueTags = relevantTagIds.map((tagId) => allTags.find((tag) => tag.id === tagId))
    return uniqueTags
  }

  const getMatchingQuantTitle = (textResponse) => {
    const { advisorId, question } = textResponse || {}
    const topicIds = question?.topics?.map((topic) => topic.id) || []

    // find the matching quant response for this advisor's text response by matching topics
    const { textAnswer } = quantResponses.find((response) => {
      const { advisorId: quantAdvisorId, question: quantQuestion } = response || {}

      // this sucks, we need to remove the levelOfAdoptionQuant topic it defeats the purpose
      if (key === 'levelOfAdoption') {
        const quantTopicKeys = quantQuestion?.topics?.map((topic) => topic.key) || []
        return quantTopicKeys.includes('levelOfAdoptionQuant')
      }

      const quantTopicIds = quantQuestion?.topics?.map((topic) => topic.id) || []

      if (advisorId !== quantAdvisorId) return false
      if (!topicIds || !quantTopicIds) return false
      const matchingTopicIds = topicIds.filter((topic) => quantTopicIds.includes(topic))
      return matchingTopicIds.length > 0
    }) || {}

    // round the textAnswer to the nearest 100th
    if (!textAnswer || Number.isNaN(textAnswer)) return null
    const score = Math.round(textAnswer * 100) / 100

    const label = getScoredLabel(activeSectionTopicKey, organization?.name, score)
    return label?.title
  }

  const Response = (response) => {
    const { displayTextAnswer, dateCreated, advisor } = response || {}
    const quantTitle = getMatchingQuantTitle(response)

    const QuantTitle = () => (
      <Box sx={{ height: '24px', display: 'flex', alignItems: 'center' }}>
        <Typography variant="captionBold">
          {quantTitle}
        </Typography>
      </Box>

    )
    const QuotationAvatar = () => (
      <Avatar sx={{ bgcolor: 'brand.paleSlate' }}>
        <Quotation sx={{ color: 'neutral.darkGrey', height: '16px', width: '16px' }} />
      </Avatar>
    )
    const Quote = () => (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="body1">{`"${displayTextAnswer}"`}</Typography>
        <Typography variant="tiny" sx={{ color: 'neutral.darkGrey' }}>
          {formatDate(dateCreated, { includeYear: true })}
        </Typography>
      </Box>
    )
    const TagChips = () => {
      const tags = getRelevantTags(advisor)
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {tags.map((tag) => (
            <Chip
              key={tag.name}
              label={<Typography variant="caption">{tag.name}</Typography>}
              size="small"
              sx={{ bgcolor: 'neutral.offWhite' }}
            />
          ))}
        </Box>
      )
    }
    return (
      <Box sx={{ display: 'flex', columnGap: 1, mb: 3 }}>
        <QuotationAvatar />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {quantTitle && <QuantTitle />}
          <Quote />
          <TagChips />
        </Box>
      </Box>
    )
  }

  const ShowAllButton = () => {
    if (showAll) return null
    if (textResponses.length <= INITIAL_DISPLAYED_RESPONSES) return null
    return (
      <Button sx={{ ml: 0, mt: 3 }} onClick={() => setShowAll(true)}>
        {`Show all ${textResponses?.length} responses`}
      </Button>
    )
  }

  if (!textResponses?.length) return null
  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 3 }}>Advisor responses:</Typography>
      {displayedResponses.map((response) => (
        <Box key={response.id}>
          <Response {...response} />
        </Box>
      ))}
      <ShowAllButton />
    </Box>

  )
}

AdvisorResponses.propTypes = {
  keyOverride: PropTypes.string
}

AdvisorResponses.defaultProps = {
  keyOverride: null
}

export default AdvisorResponses
