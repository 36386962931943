import React from 'react'

import {
  Chatbot
} from 'components'
import useAccountPageContext from '../hooks/useAccountPageContext'

const ChatbotWrapper = () => {
  const { organization } = useAccountPageContext()
  if (!organization) return null

  return (
    // 90% height is to fit the next topic button underneath
    <Chatbot filters={{ organizationId: organization.id }} height="90%" />
  )
}

export default ChatbotWrapper
