import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'

import { IconTextCombo, QuoteCarousel } from 'components'
import { ADVISOR_EMAIL_STAGE_QUOTES, ADVISOR_PASSWORD_STAGE_QUOTES } from 'utils-em'
import {
  Briefcase,
  Calendar,
  FileWithText,
  Lightbulb,
  Phone
} from 'icons'

const AdvisorExperiencePromo = ({ isEmailStage, ...rest }) => {
  const generateChip = (icon, text) => (
    <IconTextCombo
      icon={icon}
      text={text}
      typography="bodyBold"
      typographyColor="brand.navy"
      iconSize="medium"
      iconColor="brand.navy"
      sx={{ mt: 2, ml: 2 }}
    />
  )

  const quotes = isEmailStage ? ADVISOR_EMAIL_STAGE_QUOTES : ADVISOR_PASSWORD_STAGE_QUOTES

  return (
    <Box
      sx={{
        backgroundColor: 'neutral.white',
        ml: { desktop: 5, tablet: 5, mobile: 3 },
        mr: { desktop: 5, tablet: 5, mobile: 3 },
        mt: { desktop: 5, tablet: 5, mobile: 5 },
        mb: { desktop: 10, tablet: 5, mobile: 5 },
        display: 'flex',
        flexGrow: 0,
        pt: { desktop: 10, tablet: 5, mobile: 3 },
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {isEmailStage ? (
        <Box>
          <Typography variant="h2" sx={{ mb: 3 }}>Emissary is on a mission to demystify enterprise technology purchasing. You can help!</Typography>
          <Typography variant="body1">
            Earn money by helping sellers answer key questions:
          </Typography>
          {generateChip(<Lightbulb />, 'What drives tech investments?')}
          {generateChip(<Lightbulb />, 'Do they tend to build or buy?')}
          {generateChip(<Lightbulb />, 'How powerful is procurement?')}
        </Box>
      ) : (
        <Box>
          <Typography variant="h2" sx={{ mb: 3 }}>Opportunities as an advisor:</Typography>
          {generateChip(<FileWithText />, 'Tech leader and industry surveys')}
          {generateChip(<Phone />, '1:1 coaching calls with sales reps')}
          {generateChip(<Briefcase />, 'Strategic planning sessions with leading software companies')}
          {generateChip(<Calendar />, 'Advisor community events')}
        </Box>
      )}
      <QuoteCarousel quotes={quotes} />
    </Box>
  )
}

AdvisorExperiencePromo.propTypes = {
  isEmailStage: PropTypes.bool.isRequired
}

export default AdvisorExperiencePromo
