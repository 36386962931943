import React from 'react'
import {
  Box,
  Divider,
  Typography
} from '@mui/material'
import {
  ChatbotBanner
} from 'components'
import SectionHeader from '../components/SectionHeader'
import AdvisorQuoteBox from '../components/AdvisorQuoteBox'
import AdvisorResponses from '../components/AdvisorResponses'
import BulletedParagraph from '../components/BulletedParagraph'
import useAccountPageContext from '../hooks/useAccountPageContext'

const AccountSellingTips = () => {
  const {
    isChatbotVisible,
    isWeJustHeardVisible,
    activeSectionData,
  } = useAccountPageContext()

  // eslint-disable-next-line react/prop-types
  const SellingTip = ({ title, quote, paragraph }) => (
    <>
      <Box sx={{ pl: 3, mb: 3 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>{title}</Typography>
        <BulletedParagraph paragraph={paragraph} />
      </Box>
      {quote && (
        <Box sx={{ mb: 3 }}>
          <AdvisorQuoteBox quote={quote} />
        </Box>
      )}
    </>
  )

  return (
    <Box>
      {isChatbotVisible && (
        <Box sx={{ mb: 5 }}>
          <ChatbotBanner embeddedInKnowledgePage weJustHeardSectionPresent={isWeJustHeardVisible} />
        </Box>
      )}
      <Box sx={{ mb: 5, pl: 3 }}>
        <SectionHeader />
      </Box>
      <Box>
        {activeSectionData?.map((tip, index) => (
          <Box key={tip.title} sx={{ mb: 5 }}>
            <SellingTip {...tip} />
            {index < activeSectionData.length - 1 && <Divider sx={{ mb: 5, borderBottomWidth: '1px' }} />}
          </Box>
        ))}
      </Box>
      <Box sx={{ pl: 3 }}>
        <AdvisorResponses />
      </Box>
    </Box>
  )
}

export default AccountSellingTips
