import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Box } from '@mui/material'
import { TextValidator } from 'react-material-ui-form-validator'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  ConfirmDialog,
  EmailChipInput,
  SurveySelector,
  RadioButtonGroup,
  Toggle
} from 'components'
import {
  createFutureDate,
  simpleHttpFetch,
  closeDialog,
  constants,
  validateSurveyIsAssignable
} from 'utils-em'
import AssignmentValidationTable from './AssignmentValidationTable'

const DIALOG_WIDTH = '800px'

const AssignSurveysDialog = ({ assignmentIdCallback }) => {
  const [survey, setSurvey] = React.useState(null)
  const [emails, setEmails] = React.useState([])
  const [expiration, setExpiration] = React.useState(createFutureDate(new Date(), 0, 0, constants.DEFAULT_EXPIRATION_DAYS))
  const [embedInEmail, setEmbedInEmail] = React.useState(false)
  const [validationData, setValidationData] = React.useState(null)
  const [newAssignmentIds, setNewAssignmentIds] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [roleType, setRoleType] = React.useState('departed')
  const validData = validationData?.filter((vd) => vd.valid) || []
  const validEmails = validData?.map((vd) => vd.email)
  const isInputValid = survey && emails?.length

  const roleTypeOptions = [
    {
      value: 'departed',
      label: (
        <>
          <Box>Use most recent departed account</Box>
          <Box typography="caption" color="neutral.disabled">
            The survey will be sent to an advisor for their most recent departed role.
          </Box>
        </>
      ),
    },
    {
      value: 'inseat',
      label: (
        <>
          <Box>Use in-seat account</Box>
          <Box typography="caption" color="neutral.disabled">
            The survey will be sent to an advisor for their in-seat role.
          </Box>
        </>
      ),
    }
  ]

  React.useEffect(() => {
    if (assignmentIdCallback) assignmentIdCallback(newAssignmentIds)
    if (newAssignmentIds) closeDialog()
  }, [newAssignmentIds])

  const handleValidate = async () => {
    setIsLoading(true)
    const validatedData = await validateSurveyIsAssignable(survey?.id, emails, roleType === 'inseat', embedInEmail)
    setValidationData(validatedData)
    setIsLoading(false)
  }

  const handleAssign = async () => {
    setIsLoading(true)
    const res = await simpleHttpFetch(
      `${__API_HOST__}/v1/survey_assignments/create`,
      {
        method: 'POST',
        body: JSON.stringify({
          surveyId: survey?.id,
          emails: validEmails,
          expiration,
          isInseat: roleType === 'inseat',
          embedInEmail
        })
      }
    )
    setNewAssignmentIds(await res.json())
    setIsLoading(false)
  }

  const inputStepDialogProps = {
    description: (
      <Box sx={{ width: DIALOG_WIDTH, maxWidth: DIALOG_WIDTH, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <SurveySelector onChange={setSurvey} />
        <DatePicker
          views={['day', 'month', 'year']}
          label="Expiration Date"
          value={moment(expiration)}
          onChange={(date) => setExpiration(new Date(date))}
          renderInput={(params) => (
            <TextValidator
              sx={{ width: '100%' }}
              value={expiration}
              margin="normal"
              validators={['required']}
              errorMessages={['Required Field']}
              {...params}
            />
          )}
        />
        <Box>
          <EmailChipInput initialEmails={emails} emailCallback={setEmails} />
          <Box typography="caption" color="neutral.disabled">
            You may also paste in comma, space, or newline separated emails.
          </Box>
        </Box>
        <RadioButtonGroup
          options={roleTypeOptions}
          value={roleType}
          handleChange={(e) => setRoleType(e.target.value)}
          orientation="vertical"
        />
        <Toggle
          value={embedInEmail}
          onChange={() => setEmbedInEmail(!embedInEmail)}
          label={(
            <>
              <Box>Embed in email</Box>
              <Box typography="caption" color="neutral.disabled">
                Survey will be sent once via email, with no generic blaster email and no follow-ups.
              </Box>
            </>
          )}
        />
      </Box>
    ),
    actions: [
      {
        name: 'Validate',
        preventClose: true,
        disabled: !isInputValid || isLoading,
        action: handleValidate
      }
    ]
  }

  const assignStepDialogProps = {
    description: (
      <Box sx={{ width: DIALOG_WIDTH, maxWidth: DIALOG_WIDTH, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {validationData?.length > 0 && (
          <Box>
            <Box sx={{ mb: 2 }}>Validation results:</Box>
            <AssignmentValidationTable validationData={validationData || []} />
          </Box>
        )}
      </Box>
    ),
    cancelName: 'Back',
    cancelAction: () => setValidationData(null),
    actions: [
      {
        name: 'Assign',
        disabled: !validData?.length || isLoading,
        preventClose: true,
        action: handleAssign
      }
    ]
  }

  return (
    <ConfirmDialog
      title="Assign surveys"
      size="xl"
      {...(!validationData ? inputStepDialogProps : assignStepDialogProps)}
    />
  )
}

AssignSurveysDialog.defaultProps = {
  assignmentIdCallback: null
}

AssignSurveysDialog.propTypes = {
  assignmentIdCallback: PropTypes.func,
}

export default AssignSurveysDialog
