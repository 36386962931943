import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography
} from '@mui/material'
import {
  useEngagement,
  useSessionUser
} from 'hooks'

const ProposalMessage = ({ engagementId }) => {
  const { isUnlimitedByAccount } = useSessionUser()
  const {
    isAdvisor,
    advisor,
    customerUser,
    daysUntilProposalExpires,
    isParticipant
  } = useEngagement(engagementId)
  if (isAdvisor) {
    return <>TODO</>
  }
  if (isParticipant) {
    return (
      <>
        <Typography variant="h3">
          {`${customerUser.firstName} will schedule your call with ${advisor.firstName}.`}
        </Typography>
        <Typography variant="body1">
          {`${customerUser.firstName} started an engagement with ${advisor.firstName}. You will be notified and Zoom details will be provided once the call is scheduled.`}
        </Typography>
      </>
    )
  }

  return (
    <>
      <Typography variant="h3">
        {`${isUnlimitedByAccount ? 'Emissary' : advisor.firstName} is reviewing your call proposal.`}
      </Typography>
      {isUnlimitedByAccount
        ? (
          <Typography variant="body1">
            {`Emissary is working to match you with an advisor who can cover your desired topics.
          You will receive updates on the status of your proposal via email.`}
          </Typography>
          ) : (
            <Typography variant="body1">
              {`The advisor has ${daysUntilProposalExpires} days to review your proposal.
            Once they reach a decision, you’ll receive an email notification.`}
            </Typography>
          )}
    </>
  )
}

ProposalMessage.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default ProposalMessage
