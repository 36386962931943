import PropTypes from 'prop-types'
import React from 'react'
import {
  Button
} from '@mui/material'
import {
  Share
} from 'icons'

const EngagementPrepGuideLink = ({ includeIcon }) => (
  <Button
    variant="text"
    sx={{ ml: 0 }}
    target="_blank"
    href="https://emissary.io/wp-content/uploads/2024/08/Emissary-Call-Prep-Guide.pdf"
  >
    Engagement prep guide
    { includeIcon ? <Share sx={{ verticalAlign: 'top', pl: 1 }} /> : null }
  </Button>
)

EngagementPrepGuideLink.defaultProps = {
  includeIcon: false
}

EngagementPrepGuideLink.propTypes = {
  includeIcon: PropTypes.bool
}

export default EngagementPrepGuideLink
