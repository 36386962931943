import { useSelector } from 'react-redux'
import {
  buildJsonApiOne,
  getAdvisorRelevantCompanies,
  getAdvisorMostRecentRelevantCompany,
  getAdvisorMostRecentRelevantRole,
  isAdvisorAvailable,
  sortActiveCompaniesByRecency,
} from 'utils-em'

export default function useAdvisor (advisorId) {
  return useSelector(({ data }) => {
    const advisor = buildJsonApiOne(data, 'advisors', advisorId)

    /**
     * Get expertises for a given organization for this advisor.
     * Expertises are filtered by a minimum rating and sorted by highest to lowest rating.
     */
    const getExpertises = (orgId, minRating = 4) => {
      const { finalExpertiseRatings } = advisor || {}
      const orgExpertiseRatings = finalExpertiseRatings?.find((r) => r.organizationId === orgId)?.ratings || []
      return orgExpertiseRatings
        .filter((r) => r.rating >= minRating)
        .sort((a, b) => b.rating - a.rating)
    }

    return {
      advisor,
      companies: advisor && sortActiveCompaniesByRecency(advisor.companies?.filter((c) => !c.deleted)),
      currentCompany: advisor && advisor.companies?.find((c) => c.isCurrent),
      relevantCompanies: advisor && getAdvisorRelevantCompanies(advisor),
      recentRelevantCompany: advisor && getAdvisorMostRecentRelevantCompany(advisor),
      recentRelevantRole: advisor && getAdvisorMostRecentRelevantRole(advisor),
      isAvailable: advisor && isAdvisorAvailable(advisor),
      getExpertises
    }
  })
}
