import React from 'react'
import PropTypes from 'prop-types'
import {
  Skeleton,
  Typography
} from '@mui/material'

import {
  usePreloadImage
} from 'hooks'
import useAccountPageContext from '../hooks/useAccountPageContext'

const Logo = ({ isSmall }) => {
  const accountPage = useAccountPageContext()
  const organization = accountPage?.organization
  const loaded = accountPage?.loaded

  const [logoUrl, setLogoUrl] = React.useState(null)
  const preloaded = usePreloadImage(logoUrl)

  const loadLogoUrl = async () => {
    if (accountPage?.logo) {
      setLogoUrl(accountPage.logo)
      return
    }

    if (!accountPage?.logoGcsObjectName) return

    const url = `${__API_HOST__}/v1/accountPages/logoUrls?accountPageIds=[${accountPage.id}]`
    const res = await fetch(url, { credentials: 'include', method: 'GET' })
    const json = await res.json()
    setLogoUrl(json[accountPage.id])
  }
  React.useEffect(() => { loadLogoUrl() }, [loaded])

  if (organization && (!accountPage?.logoGcsObjectName && !accountPage?.logo)) {
    return (
      <Typography variant={isSmall ? 'h4' : 'h1'} sx={{ lineHeight: isSmall ? '40px !important' : 'inherit' }}>
        {organization?.name}
      </Typography>
    )
  }
  if (!loaded || !preloaded) return <Skeleton variant="rounded" height={isSmall ? '40px' : '80px'} width={isSmall ? '100px' : '200px'} />
  return <img alt="company-logo" src={logoUrl} height={isSmall ? '24px' : '80px'} />
}

Logo.defaultProps = {
  isSmall: false
}

Logo.propTypes = {
  isSmall: PropTypes.bool
}

export default Logo
