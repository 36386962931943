import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { lowerCase } from 'lodash'
import {
  Box,
  Button,
  Typography
} from '@mui/material'
import { ManageAccountsDialog } from 'components'
import {
  getDistinctAccountsFromARList,
  openDialog,
  useDebounce,
  buildJsonApiOne,
  canManageUser
} from 'utils-em'
import {
  useSessionUser,
  useJsonAPIGetAll
} from 'hooks'

const ManageAccountsSection = ({ userId, context }) => {
  const { searchValue } = context ? React.useContext(context) : ''
  const debounceSearchValue = useDebounce(searchValue)

  const advisorRequestFilters = [
    { name: 'status', op: 'eq', val: 'open' },
    {
      name: 'customerUsers',
      op: 'any',
      val: {
        name: 'id',
        op: 'eq',
        val: userId
      }
    }
  ]
  const { objects: advisorRequests } = useJsonAPIGetAll(
    'advisorRequests',
    {
      filters: advisorRequestFilters,
      include: ['account', 'account.organization', 'customerUsers'],
      postQueryFilters: [(ar) => (debounceSearchValue?.length ? lowerCase(ar.account.name).includes(lowerCase(debounceSearchValue)) : true)]
    }
  )

  const customerUser = useSelector(({ data }) => buildJsonApiOne(data, 'customerUsers', userId))
  const viewingUser = useSelector(({ session }) => session)
  const isLoggedInUser = viewingUser.id === userId
  const { isUnlimitedByAccount } = useSessionUser()
  const distinctAccountsList = getDistinctAccountsFromARList(advisorRequests)

  const hideManageAccountsButton = !customerUser.isActive || !(canManageUser(viewingUser, customerUser))

  return (
    <>
      <Box>
        <Box sx={{ display: 'flex', mb: 2, gap: 3, alignItems: 'center' }}>
          <Typography color="neutral.black" variant="h4">
            {isLoggedInUser ? 'My accounts' : 'Accounts'}
          </Typography>
          {!hideManageAccountsButton && (
            <Button
              variant="text"
              onClick={() => openDialog(<ManageAccountsDialog userId={userId} />)}
            >
              Manage accounts
            </Button>
          )}
        </Box>
        {(distinctAccountsList.length && isLoggedInUser) ? (
          <Typography>
            You can tailor your Emissary experience by keeping your account list up to date.
            You will receive notifications about new
            {isUnlimitedByAccount ? ' ' : ' advisors and '}
            insights based on this list.
          </Typography>
        ) : null}
        {(distinctAccountsList.length && !isLoggedInUser) ? (
          <Typography>
            {`Encourage ${customerUser.firstName} to personalize their Emissary experience by keeping their account list up to date.
            They will receive notifications about new insights based on this list.`}
          </Typography>
        ) : null}
        {(!distinctAccountsList.length && !isLoggedInUser) && (
          <Typography sx={{ display: 'flex' }}>
            {debounceSearchValue ? `${customerUser.firstName} has no accounts that match this search.`
              : 'This user has no accounts saved yet. Encourage them to personalize their Emissary experience by keeping their account list up to date. They will receive notifications about new advisors and insights based on this list.' }
          </Typography>
        )}
        {(!distinctAccountsList.length && isLoggedInUser) && (
          <Typography sx={{ display: 'flex' }}>
            You have no accounts saved yet. You can tailor your Emissary experience by keeping your account list up to date.
            You will receive notifications about new
            {isUnlimitedByAccount ? ' ' : ' advisors and '}
            insights based on this list.
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
        {distinctAccountsList.sort((a, b) => a.name.localeCompare(b.name)).map((account, idx) => (
          account.organization && (
          <Typography
            key={account.id}
            sx={{ display: 'flex', flex: 1, p: 2, border: 1, borderRadius: 1, m: 2, ml: (idx % 3 === 0 ? 0 : 2), borderColor: 'neutral.lightGrey' }}
          >
            {account.organization?.name}
          </Typography>
          )
        ))}
      </Box>
    </>
  )
}

ManageAccountsSection.defaultProps = {
  context: null
}

ManageAccountsSection.propTypes = {
  userId: PropTypes.number.isRequired,
  context: PropTypes.object
}

export default ManageAccountsSection
