import PropTypes from 'prop-types'
import React from 'react'
import {
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material'
import { Check } from 'icons'

const OnboardingStepper = ({ currentStep, forceFinalStage }) => {
  const renderStep = (step, label) => {
    const isComplete = currentStep > step
    const isFuture = currentStep < step
    const icon = isComplete || forceFinalStage ? <Check sx={{ padding: '4px', color: 'brand.slate', borderRadius: '50%', backgroundColor: 'brand.paleSlate' }} /> : null

    let textProps = { variant: 'tinyBold', color: 'neutral.black' }
    if (isComplete) {
      textProps = { variant: 'tiny', color: 'neutral.black' }
    } else if (isFuture) {
      textProps = { variant: 'tiny', color: 'neutral.disabled' }
    }
    return (
      <Step key={step}>
        <StepLabel icon={icon} sx={{ '& .MuiStepLabel-label': { display: 'flex' } }}>
          <Typography {...textProps}>{label}</Typography>
        </StepLabel>
      </Step>
    )
  }

  return (
    <Stepper activeStep={currentStep - 1} sx={{ mb: 3 }}>
      {renderStep(1, 'Email')}
      {renderStep(2, 'Profile')}
      {renderStep(3, 'Expertise')}
      {renderStep(4, 'Payment')}
    </Stepper>
  )
}

OnboardingStepper.defaultProps = {
  forceFinalStage: false
}

OnboardingStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  forceFinalStage: PropTypes.bool
}

export default OnboardingStepper
