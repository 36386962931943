import React from 'react'
import {
  Box
} from '@mui/material'

import {
  useSessionUser
} from 'hooks'
import {
  AdvisorsList,
  AdvisorsListSort
} from 'components'
import {
  navigateTo
} from 'utils-em'
import useAccountPageContext from '../hooks/useAccountPageContext'

const Coaching = () => {
  const { organization } = useAccountPageContext()
  const { isUnlimitedByAccount } = useSessionUser()
  const [sort, setSort] = React.useState('-onboarding_completed')

  // redirect to talk-to-someone page if user has unlimited contract
  React.useEffect(() => {
    if (!organization?.id || !isUnlimitedByAccount) return
    navigateTo(`/c/talk-to-someone/${organization.id}`)
  }, [organization?.id])

  if (!organization) return null

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <AdvisorsListSort defaultSort={sort} onSortChange={setSort} excludedSorts={['company']} />
      </Box>
      {organization && <AdvisorsList orgId={organization?.id} sort={sort} />}
    </>
  )
}

export default Coaching
