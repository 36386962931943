import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography
} from '@mui/material'

import {
  Bullet
} from 'icons'

const BulletedParagraph = ({ paragraph }) => {
  const splitParagraph = paragraph?.replace(/•/gi, '').split('\n')
  const bullets = splitParagraph?.length ? splitParagraph : [paragraph]
  const nonEmptyBullets = bullets.filter((bullet) => bullet?.trim()?.length > 0)

  // eslint-disable-next-line react/prop-types
  const ParagraphBullet = ({ text }) => (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Bullet />
      <Typography variant="body1">{text}</Typography>
    </Box>
  )

  if (!paragraph) return null

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {nonEmptyBullets.map((bullet) => (
        <ParagraphBullet key={bullet} text={bullet} />
      ))}
    </Box>
  )
}

BulletedParagraph.propTypes = {
  paragraph: PropTypes.string.isRequired
}

export default BulletedParagraph
