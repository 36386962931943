import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Link,
  Typography
} from '@mui/material'
import { Flag } from 'icons'
import {
  openDialog
} from 'utils-em'

import SuggestAnAdvisorDialog from './SuggestAnAdvisorDialog'

const SuggestAnAdvisorSection = ({ organizationId }) => (
  <Box id="suggest-advisor-section" sx={{ bgcolor: 'brand.lightestPurple', borderRadius: 2, p: 3, my: 1 }}>
    <Flag sx={{ color: 'brand.purple' }} />
    <Typography variant="caption" sx={{ display: 'block', mb: 1 }}>
      Do you know of an executive who recently left this company that&nbsp;
      <b>we should recruit?</b>
    </Typography>
    <Link variant="buttonL" onClick={() => openDialog(<SuggestAnAdvisorDialog organizationId={organizationId} />)} sx={{ display: 'block' }}>
      Suggest an advisor
    </Link>
  </Box>
)

SuggestAnAdvisorSection.propTypes = {
  organizationId: PropTypes.string.isRequired
}

export default SuggestAnAdvisorSection
