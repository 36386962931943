/* eslint-disable react/prop-types */
import React from 'react'
import {
  Box,
  Grid,
  Typography
} from '@mui/material'

import {
  getItemsInEqualColumns,
} from 'utils-em'
import SectionHeader from '../components/SectionHeader'
import useAccountPageContext from '../hooks/useAccountPageContext'

const TechAlignment = () => {
  const { activeSectionData } = useAccountPageContext()
  const columns = getItemsInEqualColumns(activeSectionData?.sort() || [], 3)

  const TechStackItem = ({ item }) => {
    const [header, subheader] = item.split('(')
    return (
      <Grid item xs={6} sx={{ px: 2, py: 1 }}>
        <Typography variant="bodyBold">{header.trim()}</Typography>
        {subheader && (<Typography variant="tiny">{subheader.replace(')', '')}</Typography>)}
      </Grid>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <SectionHeader />
      <Grid container>
        {columns.map((col) => (
          <Grid item xs={4}>
            {col.map((stackItem) => (
              <TechStackItem item={stackItem} />
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default TechAlignment
