import { store } from 'store'
import { JsonAPI } from '../store/JsonAPI'

export const ACTION_CONSTANTS = {
  clickedXAvailableAdvisors: 'clicked_x_available_advisors',
  clickedAccountPageTab: 'clicked_account_page_tab',
  clickedAccountPageJump: 'clicked_account_page_jump',
  copiedInsightLink: 'copied_insight_link',
  viewedInsight: 'viewed_insight',
  clickedInsightCard: 'clicked_insight_card',
  viewedProfile: 'viewed_profile',
  isNotIdle: 'admin_not_idle',
  outOfAccountCredits: 'out_of_account_credits',
  viewedSellerProfile: 'viewed_seller_profile', // user (manager/po) seller activity page
  viewedHomePage: 'viewed_home_page', // user visits the homepage
  viewedTeamActivity: 'viewed_team_activity', // user (Manager/PO) visits Team Activity Page
  viewedMyTeam: 'viewed_my_team', // user (manager/PO) visits the my teams page
  viewedMyActivity: 'viewed_my_activity', // user visits my activity
  viewedMyProfile: 'viewed_my_profile', // user visits my profile
  viewedEngagementDetails: 'viewed_engagement_details', // engagement details (all states)
  searchedAccounts: 'searched_accounts', // searched accounts (3.0)
  clickedAccount: 'clicked_account', // clicked an account (3.0)
  viewedAccountsByIndustry: 'viewed_accounts_by_industry', // viewed accounts by industry (3.0)
  viewedAccountsAlphabetically: 'viewed_accounts_alphabetically', // viewed accounts alphabetically (3.0)
  proposedCallBlocked: 'proposed_call_block' // CU tried to propose a call but was blocked
}

export function recordUserAction (action, targetObject = null, targetObjectType = null, payload = {}, userId = null) {
  const body = {
    type: 'userActions',
    action,
    targetObjectId: targetObject ? String(targetObject.id) : null,
    targetObjectType: targetObjectType || (targetObject && targetObject.type),
    payload: payload ? JSON.stringify(payload) : null
  }

  if (userId) {
    body.userId = userId
  }
  store.dispatch(JsonAPI.create(body))
}
